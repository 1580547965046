import React from "react";
import { PlausibilityCheckOverviewComponent } from "./overview/overview.component";
import { TypeOverviewComponent } from './type_overview/type_overview.component';
import { TypeCreateComponent } from './type_create/type_create.component';
import { EditPlausibilityCheckTypeComponent } from './type_edit/type_edit.component';
import { CheckOverviewComponent } from './check_overview/check_overview.component';
import { CheckCreateComponent } from './check_create/check_create.component';
import { CheckDuplicateComponent } from './check_duplicate/check_duplicate.component';
import { RulesInfoComponent } from '../rules_info/rules_info.component';
import { CheckEditComponent } from './check_edit/check_edit.component';
import { Route, Routes } from "react-router-dom";
import { PolicyOverviewComponent } from "./policy/policy_overview/policy_overview.component";
import { PolicyMandatoryCategoryCreateComponent } from "./policy/policy_mandatory_category/policy_mandatory_category_create/policy_mandatory_category_create.component";
import { PolicyMandatoryCategoryUpdateComponent } from "./policy/policy_mandatory_category/policy_mandatory_category_update/policy_mandatory_category_update.component";
import { PolicyMandatoryCategoryByMasspointCreateComponent } from "./policy/policy_mandatory_category_by_masspoint/policy_mandatory_category_by_masspoint_create/policy_mandatory_category_by_masspoint_create.component";
import { PolicyMandatoryCategoryByMasspointUpdateComponent } from "./policy/policy_mandatory_category_by_masspoint/policy_mandatory_category_by_masspoint_update/policy_mandatory_category_by_masspoint_update.component";
import { PolicySoftSeamCreateComponent } from "./policy/policy_soft_seam/policy_soft_seam_create/policy_soft_seam_create.component";
import { PolicySoftSeamUpdateComponent } from "./policy/policy_soft_seam/policy_soft_seam_update/policy_soft_seam_update.component";
import { PolicyAdditionRankCreateComponent } from "./policy/policy_addition_rank/policy_addition_rank_create/policy_addition_rank_create.component";
import { PolicyAdditionRankUpdateComponent } from "./policy/policy_addition_rank/policy_addition_rank_update/policy_addition_rank_update.component";
import { PolicyDefaultAdditionByArticleTypeCreateComponent } from "./policy/policy_default_addition/policy_default_addition_by_articletype_create/policy_default_addition_by_articletype_create.component";
import { PolicyDefaultAdditionByArticleTypeUpdateComponent } from "./policy/policy_default_addition/policy_default_addition_by_articletype_update/policy_default_addition_by_articletype_update.component";
import { PolicyMandatoryByAttributeCreateComponent } from "./policy/policy_mandatory_by_attribute/policy_mandatory_by_attribute_create/policy_mandatory_by_attribute_create.component";
import { PolicyMandatoryByAttributeUpdateComponent } from "./policy/policy_mandatory_by_attribute/policy_mandatory_by_attribute_update/policy_mandatory_by_attribute_update.component";
import { PolicyDefaultAdditionByQualityAndAttributeCreateComponent } from "./policy/policy_default_addition_by_quality_and_attribute/policy_default_addition_by_quality_and_attribute_create/policy_default_addition_by_qu_and_att_create.component";
import { PolicyDefaultAdditionByQualityAndAttributeUpdateComponent } from "./policy/policy_default_addition_by_quality_and_attribute/policy_default_addition_by_quality_and_attribute_update/policy_default_addition_by_qu_and_att_update.component";
import { PolicyExcludeAdditionByMasspointCreateComponent } from "./policy/policy_exclude_addition/policy_exclude_addition_by_masspoint_create/policy_exclude_addition_by_masspoint_create.component";
import { PolicyExcludeAdditionByMasspointUpdateComponent } from "./policy/policy_exclude_addition/policy_exclude_addition_by_masspoint_update/policy_exclude_addition_by_masspoint_update.component";
import { PolicyUpperThresholdCreateComponent } from "./policy/policy_upper_threshold/policy_upper_threshold_create/policy_upper_threshold_create.component";
import { PolicyUpperThresholdUpdateComponent } from "./policy/policy_upper_threshold/policy_upper_threshold_update/policy_upper_threshold_update.component";
import { PolicyExcludeAdditionByMasspointValueCreateComponent } from "./policy/policy_exclude_addition_by_masspoint_value/policy_exclude_addition_by_masspoint_value_create/policy_exclude_addition_by_masspoint_value_create.component";
import { PolicyExcludeAdditionByMasspointValueUpdateComponent } from "./policy/policy_exclude_addition_by_masspoint_value/policy_exclude_addition_by_masspoint_value_update/policy_exclude_addition_by_masspoint_value_update.component";
import { PolicyAdjustRangesByMasspointCreateComponent } from "./policy/policy_adjust_ranges_by_masspoint/policy_adjust_ranges_by_masspoint_create/policy_adjust_ranges_by_masspoint_create.component";
import { PolicyAdjustRangesByMasspointUpdateComponent } from "./policy/policy_adjust_ranges_by_masspoint/policy_adjust_ranges_by_masspoint_update/policy_adjust_ranges_by_masspoint_update.component";
import { PolicyAdjustMaximumRangeCreateComponent } from "./policy/policy_adjust_maximum_range/policy_adjust_maximum_range_create/policy_adjust_maximum_range_create.component";
import { PolicyAdjustMaximumRangeUpdateComponent } from "./policy/policy_adjust_maximum_range/policy_adjust_maximum_range_update/policy_adjust_maximum_range_update.component";
import { PolicySideSeamComponent } from "./policy/policy_side_seam/policy_side_seam.component";
import { PolicyScarTreatmentComponent } from "./policy/policy_scar_treatment/policy_scar_treatment.component";
import { PolicyFingersAndToesComponent } from "./policy/policy_fingers_and_toes/policy_fingers_and_toes.component";

export const PlausibilityCheckComponent = () => {
    return (
        <Routes>
            <Route element={<PlausibilityCheckOverviewComponent />} path="/" />
            <Route element={<TypeOverviewComponent />} path="/type" />
            <Route element={<TypeCreateComponent />} path="/type/create" />
            <Route element={<EditPlausibilityCheckTypeComponent />} path="/type/:id/edit" />
            <Route element={<CheckOverviewComponent />} path="/check" />
            <Route element={<CheckCreateComponent />} path="/check/create" />
            <Route element={<CheckDuplicateComponent />} path="/check/duplicate/:checkId" />
            <Route element={<RulesInfoComponent />} path="/check/:checkId/rule/:isVersion" />
            <Route element={<RulesInfoComponent />} path="/check/:checkId/rule/edit/:isVersion" />
            <Route element={<CheckEditComponent />} path="/check/:id/edit" />

            <Route element={<PolicyOverviewComponent />} path="/policy" />
            
            <Route element={<PolicyMandatoryCategoryCreateComponent />} path="/policy/mandatorycategory/create" />
            <Route element={<PolicyMandatoryCategoryUpdateComponent />} path="/policy/mandatorycategory/:id/edit" />
            
            <Route element={<PolicyMandatoryCategoryByMasspointCreateComponent />} path="/policy/mandatorycategorybymasspoint/create" />
            <Route element={<PolicyMandatoryCategoryByMasspointUpdateComponent />} path="/policy/mandatorycategorybymasspoint/:id/edit" />
            
            <Route element={<PolicySoftSeamCreateComponent />} path="/policy/softseam/create" />
            <Route element={<PolicySoftSeamUpdateComponent />} path="/policy/softseam/:id/edit" />
            
            <Route element={<PolicyAdditionRankCreateComponent />} path="/policy/additionrank/create" />
            <Route element={<PolicyAdditionRankUpdateComponent />} path="/policy/additionrank/:id/edit" />
            
            <Route element={<PolicyDefaultAdditionByArticleTypeCreateComponent />} path="/policy/defaultadditionbyarticletype/create" />
            <Route element={<PolicyDefaultAdditionByArticleTypeUpdateComponent />} path="/policy/defaultadditionbyarticletype/:id/edit" />
            
            <Route element={<PolicyMandatoryByAttributeCreateComponent />} path="/policy/defaultbyattribute/create" />
            <Route element={<PolicyMandatoryByAttributeUpdateComponent />} path="/policy/defaultbyattribute/:id/edit" />
            
            <Route element={<PolicyDefaultAdditionByQualityAndAttributeCreateComponent />} path="/policy/defaultadditionbyqualityandattribute/create" />
            <Route element={<PolicyDefaultAdditionByQualityAndAttributeUpdateComponent />} path="/policy/defaultadditionbyqualityandattribute/:id/edit" />
            
            <Route element={<PolicyExcludeAdditionByMasspointCreateComponent />} path="/policy/excludeadditionbymasspoint/create" />
            <Route element={<PolicyExcludeAdditionByMasspointUpdateComponent />} path="/policy/excludeadditionbymasspoint/:id/edit" />
            
            <Route element={<PolicyUpperThresholdCreateComponent />} path="/policy/upperthreshold/create" />
            <Route element={<PolicyUpperThresholdUpdateComponent />} path="/policy/upperthreshold/:id/edit" />
            
            <Route element={<PolicyExcludeAdditionByMasspointValueCreateComponent />} path="/policy/excludeadditionbymasspointvalue/create" />
            <Route element={<PolicyExcludeAdditionByMasspointValueUpdateComponent />} path="/policy/excludeadditionbymasspointvalue/:id/edit" />
            
            <Route element={<PolicyAdjustRangesByMasspointCreateComponent />} path="/policy/adjustrangesbymasspoint/create" />
            <Route element={<PolicyAdjustRangesByMasspointUpdateComponent />} path="/policy/adjustrangesbymasspoint/:id/edit" />
            
            <Route element={<PolicyAdjustMaximumRangeCreateComponent />} path="/policy/adjustsecondrange/create" />
            <Route element={<PolicyAdjustMaximumRangeUpdateComponent />} path="/policy/adjustsecondrange/:id/edit" />      
            
            <Route element={<PolicyFingersAndToesComponent />} path="/policy/fingersandtoes/create" />
            <Route element={<PolicyFingersAndToesComponent />} path="/policy/fingersandtoes/:id/edit" />

            <Route element={<PolicySideSeamComponent />} path="/policy/sideseam/create" />
            <Route element={<PolicySideSeamComponent />} path="/policy/sideseam/:id/edit" />
            <Route element={<PolicyScarTreatmentComponent />} path="/policy/scartreatment/create" />
            <Route element={<PolicyScarTreatmentComponent />} path="/policy/scartreatment/:id/edit" />
        </Routes>
    )
}