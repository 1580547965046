import { Grid } from "@mui/material";
import { PrimaryButtonComponent } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

interface EditorButtonProps {
    canExecute: boolean;
    save: (useForce?: boolean) => void;
    cancelSave: () => void;
}

export const EditorButtonComponent: React.FC<EditorButtonProps> = ({ canExecute, save, cancelSave  }) => {
    return (
        <Grid item
            container
            justifyContent="flex-end"
            columnSpacing={2}>
            <Grid item>
                <PrimaryButtonComponent
                    onClick={cancelSave}>
                    <ClearIcon /> Abbrechen
                </PrimaryButtonComponent>
            </Grid>
            <Grid item>
                <PrimaryButtonComponent
                    disabled={!canExecute}
                    onClick={save}>
                    <AddIcon /> Speichern
                </PrimaryButtonComponent>
            </Grid>
        </Grid>
    );
};