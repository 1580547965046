import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import { MasspointBodySideEnumValues } from "models/masspoints/enums/body_side.enum";
import { IPositionCategoryItemActions } from "models/position_category/position_category_item/position_category_item_actions";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { FlexBox, Right, PrimaryButtonComponent, TextBoxComponent } from 'shared/shared';
import * as style from "./position_category_item_editor.style";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { IPositionCategoryItem } from "models/position_category/position_category_editor";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { getConfig } from "services/config.service";
import { CalingaLinkComponent } from "shared/components/editors/calinga_link/calinga_link.component";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";

interface PositionCategoryItemEditorComponentProps {
    isSideSeamAllowed: boolean;
    editorActions: IPositionCategoryItemActions,
    loadedBodyAreas: IAvailableBodyArea[],
    currentEditedPositionCategoryItem: IPositionCategoryItem,
    canSave: boolean,
}

export const PositionCategoryItemEditorComponent: React.FC<PositionCategoryItemEditorComponentProps> = ({ 
    isSideSeamAllowed,
    editorActions, 
    loadedBodyAreas, 
    currentEditedPositionCategoryItem, 
    canSave 
}) => {
    const renderBodyAreas = loadedBodyAreas.map((bodyArea, index) => {
        return (
            <FormControlLabel key={index}
                control={
                    <Checkbox
                        checked={currentEditedPositionCategoryItem.bodyAreas.some(x => x.id === bodyArea.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.toggleSelectionBodyArea(bodyArea)}
                    />
                }
                label={bodyArea.name}
            />
        )
    });

    const renderBodySides = MasspointBodySideEnumValues.map((bodySide, index) => {
        return (
            <FormControlLabel
                key={index}
                control={
                    <Checkbox
                        checked={currentEditedPositionCategoryItem.bodySides.some(x => x === bodySide.value)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.toggleSelectionBodySide(bodySide.value)}
                    />
                }
                label={bodySide.text}
            />
        )
    });

    const renderAdditionStartHorizontalPositions = <SelectionListComponent
        title={"Körperposition horizontal Start"}
        entityName={"Positionen horizontal Start"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedHorizontalAdditionPositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedHorizontalAdditionPositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection}
        selectEntities={(ids) => { editorActions.selectHorizontalAdditionPositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectHorizontalAdditionPositions(ids) }}
    />

    const renderAdditionStartHorizontalPrepositions = <SelectionListComponent
        title={"Präposition horizontal Start"}
        entityName={"Präpositionen horizontal Start"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedHorizontalStartPrepositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedHorizontalStartPrepositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection}
        selectEntities={(ids) => { editorActions.selectHorizontalStartPrepositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectHorizontalStartPrepositions(ids) }}
    />

    const renderAdditionStartVerticalPositions = <SelectionListComponent
        title={"Körperposition vertikal Start"}
        entityName={"Positionen vertikal Start"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedVerticalAdditionPositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedVerticalAdditionPositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection}
        selectEntities={(ids) => { editorActions.selectVerticalAdditionPositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectVerticalAdditionPositions(ids) }}
    />

    const renderAdditionStartVerticalPrepositions = <SelectionListComponent
        title={"Präposition vertikal Start"}
        entityName={"Präpositionen vertikal Start"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedVerticalStartPrepositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedVerticalStartPrepositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection}
        selectEntities={(ids) => { editorActions.selectVerticalStartPrepositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectVerticalStartPrepositions(ids) }}
    />

    const renderAdditionEndVerticalPositions = <SelectionListComponent
        title={"Körperposition vertikal Ende"}
        entityName={"Positionen vertikal Ende"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedVerticalEndAdditionPositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedVerticalEndAdditionPositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection}
        selectEntities={(ids) => { editorActions.selectVerticalEndAdditionPositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectVerticalEndAdditionPositions(ids) }}
    />

    const renderAdditionEndVerticalPrepositions = <SelectionListComponent
        title={"Präposition vertikal Ende"}
        entityName={"Präpositionen vertikal Ende"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedVerticalEndPrepositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedVerticalEndPrepositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection}
        selectEntities={(ids) => { editorActions.selectVerticalEndPrepositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectVerticalEndPrepositions(ids) }}
    />

    const renderStartHorizontalDetails = <Grid direction="row"
        container
        justifyContent="flex-start"
        alignContent="flex-end"
        alignItems="flex-end">
        <Grid item md={3}>
            <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"von"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.horizontalStartItem.start}
                onChange={(value) => editorActions.updateHorizontalStartBeginValue(value)}
            />
        </Grid>
        <Grid item md={3}>
            <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"bis"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.horizontalStartItem.end}
                onChange={(value) => editorActions.updateHorizontalStartEndValue(value)}
            />
        </Grid>
    </Grid>

    const renderAdditionEndHorizontalPositions = <SelectionListComponent
        title={"Körperposition horizontal Ende"}
        entityName={"Positionen horizontal Ende"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedHorizontalEndAdditionPositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedHorizontalEndAdditionPositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection}
        selectEntities={(ids) => { editorActions.selectHorizontalEndAdditionPositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectHorizontalEndAdditionPositions(ids) }}
    />

    const renderAdditionEndHorizontalPrepositions = <SelectionListComponent
        title={"Präposition horizontal Ende"}
        entityName={"Präpositionen horizontal Ende"}
        onFilterSelected={(searchText) => { editorActions.filterSelectedHorizontalEndPrepositions(searchText) }}
        onFilterUnselected={(searchText) => { editorActions.filterUnselectedHorizontalEndPrepositions(searchText) }}
        selectionList={currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection}
        selectEntities={(ids) => { editorActions.selectHorizontalEndPrepositions(ids) }}
        unselectEntities={(ids) => { editorActions.unselectHorizontalEndPrepositions(ids) }}
    />

    const renderEndHorizontalDetails = <Grid direction="row"
        container
        justifyContent="flex-start"
        alignContent="flex-end"
        alignItems="flex-end">
        <Grid item md={3}>
            <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"von"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.horizontalEndItem.start}
                onChange={(value) => editorActions.updateHorizontalEndFromValue(value)}
            />
        </Grid>
        <Grid item md={3}>
            <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"bis"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.horizontalEndItem.end}
                onChange={(value) => editorActions.updateHorizontalEndToValue(value)}
            />
        </Grid>
    </Grid>

    const renderStartVerticalDetails = <Grid direction="row"
        container
        justifyContent="flex-start"
        alignContent="flex-end"
        alignItems="flex-end">
        <Grid item md={3}>
            <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"von"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.verticalStartItem.start}
                onChange={(value) => editorActions.updateVerticalStartBeginValue(value)}
            />
        </Grid>
        <Grid item md={3}>
            <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"bis"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.verticalStartItem.end}
                onChange={(value) => editorActions.updateVerticalStartEndValue(value)}
            />
        </Grid>
    </Grid>

    const renderEndVerticalDetails = <Grid direction="row"
        container
        justifyContent="flex-start"
        alignContent="flex-end"
        alignItems="flex-end">
        <Grid item md={3}>
            <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"von"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.verticalEndItem.start}
                onChange={(value) => editorActions.updateVerticalEndFromValue(value)}
            />
        </Grid>
        <Grid item md={3}>
            <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"bis"}
                minValue={-20}
                maxValue={20}
                isDisabled={currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.selectedList.allItems.length === 0}
                value={currentEditedPositionCategoryItem.verticalEndItem.end}
                onChange={(value) => editorActions.updateVerticalEndToValue(value)}
            />
        </Grid>
    </Grid>

    const renderImageRequiredTypeInput = <>
        <EditorSectionHeaderComponent>Mit Foto</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Darf ein Bild zum Item hochgeladen werden?</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.isDocumentAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsDocumentAllowed(true)}
                    />
                }
                label={"Ja"}

            />
            <FormControlLabel
                control={
                    <Radio
                        checked={!currentEditedPositionCategoryItem.isDocumentAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsDocumentAllowed(false)}
                    />
                }
                label={"Nein"}
            />
        </Grid>
    </>

    const renderLengthInput = <>
        <EditorSectionHeaderComponent>Länge</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Darf eine Länge angegeben werden?</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.isLengthAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsLengthAllowed(true)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={!currentEditedPositionCategoryItem.isLengthAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsLengthAllowed(false)}
                    />
                }
                label={"Nein"}
            />
        </Grid>
        {
            currentEditedPositionCategoryItem.isLengthAllowed ?
                <Grid direction="row"
                    container
                    justifyContent="flex-start"
                    alignContent="flex-end"
                    alignItems="flex-end">
                    <Grid item md={3}>
                        <EditorDescriptionComponent>von Länge Mindestwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={3}>
                        <DecimalInputComponent
                            name={"von"}
                            minValue={0}
                            maxValue={999}
                            isDisabled={false}
                            value={currentEditedPositionCategoryItem.lengthFrom}
                            onChange={(value) => editorActions.updateLengthFrom(value)}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <EditorDescriptionComponent>bis Länge Maximalwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={3}>
                        <DecimalInputComponent
                            name={"bis"}
                            minValue={0}
                            maxValue={999}
                            isDisabled={false}
                            value={currentEditedPositionCategoryItem.lengthTo}
                            onChange={(value) => editorActions.updateLengthTo(value)}
                        />
                    </Grid>
                </Grid>
                : <></>
        }
    </>

    const renderWidthInput = <>
        <EditorSectionHeaderComponent>Breite</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Darf eine Breite angegeben werden?</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.isWidthAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsWidthAllowed(true)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={!currentEditedPositionCategoryItem.isWidthAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsWidthAllowed(false)}
                    />
                }
                label={"Nein"}
            />
        </Grid>
        {
            currentEditedPositionCategoryItem.isWidthAllowed ?
                <Grid direction="row"
                    container
                    justifyContent="flex-start"
                    alignContent="flex-end"
                    alignItems="flex-end">
                    <Grid item md={3}>
                        <EditorDescriptionComponent>von Breite Mindestwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={3}>
                        <DecimalInputComponent
                            name={"von"}
                            minValue={0}
                            maxValue={999}
                            isDisabled={false}
                            value={currentEditedPositionCategoryItem.widthFrom}
                            onChange={(value) => editorActions.updateWidthFrom(value)}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <EditorDescriptionComponent>bis Breite Maximalwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={3}>
                        <DecimalInputComponent
                            name={"bis"}
                            minValue={0}
                            maxValue={999}
                            isDisabled={false}
                            value={currentEditedPositionCategoryItem.widthTo}
                            onChange={(value) => editorActions.updateWidthTo(value)}
                        />
                    </Grid>
                </Grid>
                : <></>
        }
    </>

    const renderDisplayAsPantyTop = <>
        <EditorSectionHeaderComponent>Leibteil</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Soll als Text für "Keine Seite" der Übersetzungstext gesetzt werden?</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.isDisplayAsPantyTop}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsDisplayAsPantyTop(true)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={!currentEditedPositionCategoryItem.isDisplayAsPantyTop}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsDisplayAsPantyTop(false)}
                    />
                }
                label={"Nein"}
            />
        </Grid>
        <CalingaLinkComponent projectName={getConfig().calinga.project.general}/>
        <TextBoxComponent
            name="Leibteil Übersetzungskey"
            value={"general.panty_top"}
            onChange={() => { }}
            isReadonly={true}
        />
    </>

    const renderSideSeam = <>
        <EditorSectionHeaderComponent>Seitliche Naht</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Sollen die Positionen auch/nur für seitliche Naht verwendet werden?</EditorDescriptionComponent>
        <Grid item>        
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.sideSeamModeAllowed === SideSeamModeEnum.SideSeam}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setSideSeamMode(SideSeamModeEnum.SideSeam)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.sideSeamModeAllowed === SideSeamModeEnum.Normal}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setSideSeamMode(SideSeamModeEnum.Normal)}
                    />
                }
                label={"Nein"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.sideSeamModeAllowed === SideSeamModeEnum.Both}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setSideSeamMode(SideSeamModeEnum.Both)}
                    />
                }
                label={"Beides"}
            />    
        </Grid>
    </>

    const renderDiameterInput = <>
        <EditorSectionHeaderComponent>Durchmesser</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Darf ein Durchmesser angegeben werden?</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={currentEditedPositionCategoryItem.isDiameterAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsDiameterAllowed(true)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={!currentEditedPositionCategoryItem.isDiameterAllowed}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => editorActions.setIsDiameterAllowed(false)}
                    />
                }
                label={"Nein"}
            />
        </Grid>
        {
            currentEditedPositionCategoryItem.isDiameterAllowed ?
                <Grid direction="row"
                    container
                    justifyContent="flex-start"
                    alignContent="flex-end"
                    alignItems="flex-end">
                    <Grid item md={3}>
                        <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={3}>
                        <DecimalInputComponent
                            name={"von"}
                            minValue={0}
                            maxValue={999}
                            isDisabled={false}
                            value={currentEditedPositionCategoryItem.diameterFrom}
                            onChange={(value) => editorActions.updateDiameterFrom(value)}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={3}>
                        <DecimalInputComponent
                            name={"bis"}
                            minValue={0}
                            maxValue={999}
                            isDisabled={false}
                            value={currentEditedPositionCategoryItem.diameterTo}
                            onChange={(value) => editorActions.updateDiameterTo(value)}
                        />
                    </Grid>
                </Grid>
                : <></>
        }
    </>

    return (
        <Grid container
            direction="column"
            spacing={3}>
            { isSideSeamAllowed &&
                <Grid item>
                    {renderSideSeam}
                </Grid>
            }
            <Grid item>
                {renderImageRequiredTypeInput}
            </Grid>
            <Grid item>
                {renderLengthInput}
            </Grid>
            <Grid item>
                {renderWidthInput}
            </Grid>
            <Grid item>
                {renderDiameterInput}
            </Grid>
            <Grid item>
                <hr></hr>
            </Grid>
            <Grid item>
                {renderDisplayAsPantyTop}
            </Grid>
            <Grid container item direction="row" spacing={3}>
                <Grid item md={5}>
                    <EditorSectionHeaderComponent>Körperregion (Mehrfachauswahl möglich)</EditorSectionHeaderComponent>
                    {renderBodyAreas}
                </Grid>
                <Grid item md={5}>
                    <EditorSectionHeaderComponent>Seite (Mehrfachauswahl möglich)</EditorSectionHeaderComponent>
                    {renderBodySides}
                </Grid>
            </Grid>
            <Grid item />
            <Grid item>
                {renderAdditionStartHorizontalPrepositions}
                {renderAdditionStartHorizontalPositions}
                <EditorSectionHeaderComponent>Abweichung Horizontal Start (-20 bis 20 möglich)</EditorSectionHeaderComponent>
                {renderStartHorizontalDetails}
            </Grid>
            <Grid item>
                {renderAdditionEndHorizontalPrepositions}
                {renderAdditionEndHorizontalPositions}
                <EditorSectionHeaderComponent>Abweichung Horizontal Ende (-20 bis 20 möglich)</EditorSectionHeaderComponent>
                {renderEndHorizontalDetails}
            </Grid>
            <Grid item />
            <Grid item>
                {renderAdditionStartVerticalPrepositions}
                {renderAdditionStartVerticalPositions}
                <EditorSectionHeaderComponent>Abweichung Vertikal Start (-20 bis 20 möglich)</EditorSectionHeaderComponent>
                {renderStartVerticalDetails}
            </Grid>
            <Grid item>
                {renderAdditionEndVerticalPrepositions}
                {renderAdditionEndVerticalPositions}
                <EditorSectionHeaderComponent>Abweichung Vertikal Ende (-20 bis 20 möglich)</EditorSectionHeaderComponent>
                {renderEndVerticalDetails}
            </Grid>
            <Grid item />
            <Grid item>
                <FlexBox>
                    <Right>
                        <style.ButtonMargin>
                            <PrimaryButtonComponent
                                onClick={() => editorActions.cancelSavePositionCategoryItem()}>
                                <ClearIcon /> Abbrechen
                            </PrimaryButtonComponent>
                        </style.ButtonMargin>
                        <PrimaryButtonComponent
                            onClick={() => editorActions.savePositionCategoryItem()}
                            disabled={!canSave}
                        >
                            <AddIcon /> Übernehmen
                        </PrimaryButtonComponent>
                    </Right>
                </FlexBox>
            </Grid>
        </Grid>
    )
}