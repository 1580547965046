import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import AdditionHierarchyService from "services/addition_hierarchy.service";
import AdditionPositionService from "services/addition_position.service";
import MassPointService from "services/masspoint.service";
import { handleResponseErrors } from '../../../../shared/networking/error_handling';

export const fetchAllData = createAsyncThunk(
    'additionPosition/create/fetchAllData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getMassPoints()),
            thunkApi.dispatch(getAdditionHierarchies()),
            thunkApi.dispatch(getAdditionPositionTranslations())
        ]);
    }
)

export const getMassPoints = createAsyncThunk(
    'additionPosition/create/getMassPoints',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getAdditionHierarchies = createAsyncThunk(
    'additionPosition/create/getAdditionHierarchies',
    async (_, { getState }) => {
        const response = await AdditionHierarchyService.getAdditionHierarchies()
        handleResponseErrors(response, "ZusatzHierarchien");
        return response;
    }
)

export const getAdditionPositionTranslations = createAsyncThunk(
    'additionPosition/create/getAdditionPositionTranslations',
    async (_, { getState }) => {
        const response = await AdditionPositionService.getAdditionPositionTranslations();
        handleResponseErrors(response, "Zusatzposition Übersetzungskeys");
        return response;
    }
)

export const saveAdditionPosition = createAsyncThunk(
    'additionPosition/create/createAdditionPosition',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.addition_position_create.actualData;
        const response = await AdditionPositionService.saveAdditionPosition({
            name: data.name,
            translationKeyForName: data.selectedAdditionPositionTranslation.key,
            selectedPositionHierarchies: data.additionHierarchies.filter(x => x.isSelected).map(x => {
                return {
                    id: x.id
                }
            }),
            selectedPositionMassPointCriterias: data.masspointSelection.selectedList.allItems.map(x => {
                return {
                    massPointName: x.massPointName,
                    bodyAreaId: x.bodyArea.id,
                    massPointType: x.massPointType
                }
            })

        });
        handleResponseErrors(response, "Zusatzposition");
        return response;
    }
)