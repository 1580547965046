import { GridFilterModel } from "@mui/x-data-grid-pro";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { SideSeamModeEnum } from "./side_seam_mode.enum";
import { ISelectionList } from "shared/components/selectionComponent/models/selectionList";

export interface IPositionCategoryEditor {
    editorState: PositionCategoryEditorState;
    positionCategoryItemSet: IPositionCategoryItemSet;
    indexOfItem: number;
    sideSeamAllowed: boolean;
    name: string;
    id?: number;
}

export interface IPositionCategoryItemSet {
    positionCategoryItems: IPositionCategoryItem[];
    filteredPositionCategoryItems: IPositionCategoryItem[],
    filter: GridFilterModel;
}

export interface IPositionCategoryItem {
    sequenceId: number;
    sideSeamModeAllowed?: SideSeamModeEnum;
    isDocumentAllowed: boolean;
    isLengthAllowed: boolean;
    isWidthAllowed: boolean;
    lengthFrom: number;
    lengthTo: number;
    widthFrom: number;
    widthTo: number;
    isDiameterAllowed: boolean;
    diameterFrom: number;
    diameterTo: number;
    bodyAreas: IAvailableBodyArea[];
    bodySides: BodySideEnum[];
    isDisplayAsPantyTop: boolean;
    horizontalStartItem: ICategoryItemDetail;
    horizontalEndItem: ICategoryItemDetail;
    verticalStartItem: ICategoryItemDetail;
    verticalEndItem: ICategoryItemDetail;
}

export interface ICategoryItemDetail {
    additionPositionSelection: ISelectionList;
    prepositionSelection: ISelectionList;
    start: number;
    end: number;
}

export enum PositionCategoryEditorState {
    PositionCategory = 0,
    PositionCategoryItemCreate = 1,
    PositionCategoryItemEdit = 2
}