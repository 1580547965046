import { IPositionItemDirectionCreateItemModel } from "models/position_category/position_category_create";
import { ICategoryItemDetail, IPositionCategoryItem } from "models/position_category/position_category_editor"
import { ISelectable } from "shared/components/selectionComponent/models/selectable"
import { PositionCategoryCreateState } from "./position_category_create.model"
import { filterPositionCategoryValue } from "additions/position_categories/component/position_category_editor.reducer";
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";


export const createEmptyPositionCategoryItem = (state: PositionCategoryCreateState): IPositionCategoryItem => {
    const allHorizontalStartAdditionPositions: ISelectable[] = state.loadedData.horizontalStartAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const allHorizontalEndAdditionPositions: ISelectable[] = state.loadedData.horizontalEndAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const allVerticalStartAdditionPositions: ISelectable[] = state.loadedData.verticalStartAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const allVerticalEndAdditionPositions: ISelectable[] = state.loadedData.verticalEndAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const prepositions: ISelectable[] = state.loadedData.prepositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    return {
        sequenceId: state.actualData.positionCategoryItemSet.positionCategoryItems.length,
        bodyAreas: [],
        bodySides: [],
        isDisplayAsPantyTop: false,
        isDocumentAllowed: false,
        isLengthAllowed: false,
        isWidthAllowed: false,
        lengthFrom: 0,
        lengthTo: 0,
        widthFrom: 0,
        widthTo: 0,
        isDiameterAllowed: false,
        sideSeamModeAllowed: SideSeamModeEnum.Normal,
        diameterFrom: 0,
        diameterTo: 0,
        horizontalStartItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allHorizontalStartAdditionPositions,
                    filteredItems: allHorizontalStartAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: prepositions,
                    filteredItems: prepositions,
                }
            },
            start: 0,
            end: 0,
        },
        verticalStartItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allVerticalStartAdditionPositions,
                    filteredItems: allVerticalStartAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: prepositions,
                    filteredItems: prepositions,
                }
            },
            start: 0,
            end: 0,
        },
        verticalEndItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allVerticalEndAdditionPositions,
                    filteredItems: allVerticalEndAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: prepositions,
                    filteredItems: prepositions,
                }
            },
            start: 0,
            end: 0,
        },
        horizontalEndItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allHorizontalEndAdditionPositions,
                    filteredItems: allHorizontalEndAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: prepositions,
                    filteredItems: prepositions,
                }
            },
            start: 0,
            end: 0,
        },
    }
}

export const calculateNewSequenceIds = (state: PositionCategoryCreateState) => {
    let index = 1;
    state.actualData.positionCategoryItemSet.positionCategoryItems.forEach(x => {
        x.sequenceId = index;
        index = index + 1;
    });
}

export const updateCanSaveItem = (state: PositionCategoryCreateState) => {
    state.command.canSavePositionCategoryItem.canExecute = state.currentEditedPositionCategoryItem.bodyAreas.length > 0 &&
        state.currentEditedPositionCategoryItem.bodySides.length > 0 &&
        rangeIsValid(state.currentEditedPositionCategoryItem.horizontalStartItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.horizontalStartItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.verticalStartItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.verticalStartItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.horizontalEndItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.horizontalEndItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.verticalEndItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.verticalEndItem) &&
        lengthInputIsValid(state.currentEditedPositionCategoryItem) &&
        widthInputIsValid(state.currentEditedPositionCategoryItem) &&
        diameterIsValid(state.currentEditedPositionCategoryItem);
}

const lengthInputIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isLengthAllowed) {
        if (item.lengthFrom === 0 || item.lengthTo === 0 || item.lengthFrom >= item.lengthTo) {
            return false;
        }
    }

    return true;
}

const widthInputIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isWidthAllowed) {
        if (item.widthFrom === 0 || item.widthTo === 0 || item.widthFrom >= item.widthTo) {
            return false;
        }
    }

    if (item.isWidthAllowed && !item.isLengthAllowed) {
        return false;
    }

    return true;
}

const diameterIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isDiameterAllowed) {
        if (item.diameterFrom === 0 || item.diameterTo === 0 || item.diameterFrom >= item.diameterTo) {
            return false;
        }
    }

    return true;
}

const selectedItemsAreValid = (itemDetail: ICategoryItemDetail): boolean => {
    return (itemDetail.prepositionSelection.selectedList.allItems.length === 0 || (itemDetail.prepositionSelection.selectedList.allItems.length > 0 && itemDetail.additionPositionSelection.selectedList.allItems.length > 0));
}

const rangeIsValid = (itemDetail: ICategoryItemDetail): boolean => {
    return itemDetail.start <= itemDetail.end;
}

export const updateCanSave = (state: PositionCategoryCreateState) => {
    state.command.savePositionCategory.canExecute = state.actualData.name.length > 0 && state.actualData.positionCategoryItemSet.positionCategoryItems.length > 0;
}

export const buildSelectedDirectionRequests = (item: IPositionCategoryItem): IPositionItemDirectionCreateItemModel[] => {
    const result = new Array<IPositionItemDirectionCreateItemModel>();
    if (item.horizontalStartItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 1,
            start: item.horizontalStartItem.start,
            end: item.horizontalStartItem.end,
            selectedPositions: item.horizontalStartItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.horizontalStartItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    if (item.verticalStartItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 2,
            start: item.verticalStartItem.start,
            end: item.verticalStartItem.end,
            selectedPositions: item.verticalStartItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.verticalStartItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    if (item.horizontalEndItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 3,
            start: item.horizontalEndItem.start,
            end: item.horizontalEndItem.end,
            selectedPositions: item.horizontalEndItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.horizontalEndItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    if (item.verticalEndItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 4,
            start: item.verticalEndItem.start,
            end: item.verticalEndItem.end,
            selectedPositions: item.verticalEndItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.verticalEndItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    return result;
}

export const filterPositionCategoryItems = (state: PositionCategoryCreateState) => {
    let filteredItems = state.actualData.positionCategoryItemSet.positionCategoryItems;
    state.actualData.positionCategoryItemSet.filter.items.forEach(fil => {
        filteredItems = filterPositionCategoryValue(fil, filteredItems);
    });
    state.actualData.positionCategoryItemSet.filteredPositionCategoryItems = filteredItems;
    ;
}
