
import { Grid } from "@mui/material";
import { EditorConfigurationComponent } from "shared/components/editors/list_editor/editor_configuration.component";
import { ListEditorComponent } from "shared/components/editors/list_editor/list_editor.component";
import { DropdownComponent, FlexBox, Left } from "shared/shared";
import { IDefaultByAttributeConfigViewModel, IDefaultByAttributeEditor, IDefaultByAttributeEditorActions, IDefaultByAttributeLoadedData } from "../models/policy_mandatory_by_attribute.models";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyMandatoryByAttributeConfigComponent } from "./policy_mandatory_by_attribute_config.component";
import { ListEditorMode } from "shared/components/editors/list_editor/enums/EditorMode";
import { EditorSummaryDefinition } from "shared/components/editors/list_editor/models/EditorSummaryDefinition";
import { isValidConfigWithSubConfigs, isValidConfigWithSubConfigsByIndex } from "../common/helpers";

interface PolicyMandatoryByAttributeEditor {
    editor: IDefaultByAttributeEditor;
    actions: IDefaultByAttributeEditorActions;
    loadedData: IDefaultByAttributeLoadedData;
}

export const PolicyMandatoryByAttributeEditorComponent: React.FC<PolicyMandatoryByAttributeEditor> = (props: PolicyMandatoryByAttributeEditor) => {
    const renderContent = () => {
        return (
            <PolicyMandatoryByAttributeConfigComponent
                editor={props.editor}
                actions={props.actions}
                loadedData={props.loadedData} />
        );
    };

    const _isValidConfigWithSubConfigsByIndex = (index: number) => {
        const config = props.editor.configs[index];
        const mappedBaseData = props.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === config.addition.id);
        const isMinMaxPlaceholder = Boolean(config.addition?.placeholder?.twoRangePlaceholder);
        return isValidConfigWithSubConfigsByIndex(index, props.editor.configs, mappedBaseData, isMinMaxPlaceholder);
    }

    const isEditableConfigValid = () => {
        const editableConfig = props.editor.editableConfig;
        const mappedBaseData = props.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
        const isMinMaxPlaceholder = Boolean(editableConfig.addition?.placeholder?.twoRangePlaceholder);
        return !doesExist(props.editor.editableSubConfig) && isValidConfigWithSubConfigs(props.editor.editableConfig, mappedBaseData, isMinMaxPlaceholder);
    }

    const renderConfig = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isEditableConfigValid()}
            disabledEditorButtons={doesExist(props.editor.editableSubConfig)}
            onTakeConfiguration={() => props.actions.onTakeConfig()}
            onCancelConfiguration={props.actions.onCancelConfig}
        />
    }

    const editorSummaryDefinitions: EditorSummaryDefinition[] = [{
        heading: "Zusätze",
        flex: 1,
        displayValue: (value: IDefaultByAttributeConfigViewModel) => value.addition.option
    }];

    const getEditorMode = () => {
        if (doesExist(props.editor.editableConfig)) {
            return props.editor.editableConfigIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        }
        else {
            return ListEditorMode.None;
        }
    }

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid item>
                <FlexBox>
                    <Left>
                        <DropdownComponent name="Attribute Kategorie"
                            isReadonly={isNullOrWhitespace(props.editor.policy.name)}
                            data={props.loadedData.attributeCategories}
                            isRequired={true}
                            onSelect={(e) => props.actions.onSelectCategory(e)}
                            displayedProperties={["erpId", "name"]}
                            selectedValue={props.editor.selectedCategory}
                        />
                        <DropdownComponent name="Attribute"
                            isReadonly={props.editor.selectedCategory == null}
                            data={props.editor.selectedCategory?.availableAdditionAttributes}
                            isRequired={true}
                            onSelect={(e) => props.actions.onSelectAttribute(e)}
                            displayedProperties={["code", "name"]}
                            selectedValue={props.editor.selectedAttribute}
                        />
                    </Left>
                </FlexBox>
            </Grid>
            {
                props.editor.selectedAttribute != null &&
                <Grid item md={12}>
                    <ListEditorComponent
                        addButtonText="Neue Konfiguration hinzufügen"
                        editorSummaryDefinitions={editorSummaryDefinitions}
                        items={props.editor.configs}
                        isValid={_isValidConfigWithSubConfigsByIndex}
                        onEditConfiguration={props.actions.onEditConfig}
                        onDeleteConfiguration={props.actions.onDeleteConfig}
                        onNewConfiguration={props.actions.onNewConfig}
                        renderConfiguration={props.editor.editableConfig != null ? renderConfig : null}
                        editorMode={getEditorMode()}
                        editableConfigurationIndex={props.editor.editableConfigIndex}
                    />
                </Grid>
            }
        </Grid>
    );
};