import { Grid } from "@mui/material";
import { EditorConfigurationBox } from "./list_editor.style";
import { PrimaryButtonComponent } from "shared/shared";
import { ReactNode } from "react";
import { doesExist } from "services/validation.service";

interface EditorConfigurationProps {
    renderContent:  () => ReactNode;
    disabledEditorButtons?: boolean;
    disabledTakeConfiguration: boolean;
    onTakeConfiguration: ( ) => {};
    onCancelConfiguration: ( ) => {};
}

export const EditorConfigurationComponent: React.FC<EditorConfigurationProps> = ({
    renderContent,
    disabledEditorButtons,
    disabledTakeConfiguration,
    onTakeConfiguration,
    onCancelConfiguration,
}) => {

    let disableButtons = doesExist(disabledEditorButtons) ? disabledEditorButtons : false; 

    const renderButtons = () => {
        return <Grid item
            container
            justifyContent="flex-end"
            spacing={2}>
            <Grid item>
                <PrimaryButtonComponent
                    disabled={disableButtons}
                    onClick={onCancelConfiguration}>
                    Verwerfen
                </PrimaryButtonComponent>
            </Grid>
            <Grid item>
                <PrimaryButtonComponent
                    disabled={disabledTakeConfiguration || disableButtons}
                    onClick={onTakeConfiguration}>
                    Übernehmen
                </PrimaryButtonComponent>
            </Grid>
        </Grid> 
    }

    return (
        renderContent != null ? <Grid item>
            <EditorConfigurationBox>
                {renderContent()}
                {renderButtons()}
            </EditorConfigurationBox>
        </Grid> : <></>
    )};