import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAdditionPositions, fetchAllData, fetchBodyAreas, getPositionCategory, updatePositionCategory, getPrepositions } from "./position_category_edit.thunks";
import { IPositionCategoryItem, PositionCategoryEditorState } from "models/position_category/position_category_editor";
import { calculateNewSequenceIds, createEmptyPositionCategoryItem, filterPositionCategoryItems, updateCanSave, updateCanSaveItem } from "./position_category_edit.reducer";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { AdditionPositionFilterHelper } from "models/addition_position/addition_position_overview";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { PositionCategoryEditState } from "./position_category_edit.model";
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { SideSeamModeEnum } from 'models/position_category/side_seam_mode.enum';
import { filterSelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from 'shared/components/selectionComponent/selectionList.helper';

const initialState: PositionCategoryEditState = {
    loadedData: {
        positionCategoryId: 0,
        positionCategory: null,
        bodyAreas: [],
        horizontalStartAdditionPositions: [],
        verticalStartAdditionPositions: [],
        horizontalEndAdditionPositions: [],
        verticalEndAdditionPositions: [],
        prepositions: []
    },
    actualData: {
        name: "",
        sideSeamAllowed: false,
        positionCategoryItemSet: {
            positionCategoryItems: [],
            filteredPositionCategoryItems: [],
            filter: { items: [] },
        },
        editorState: PositionCategoryEditorState.PositionCategory,
        indexOfItem: 0,
    },
    currentEditedPositionCategoryItem: {
        sequenceId: 0,
        bodyAreas: [],
        bodySides: [],
        isDocumentAllowed: false,
        isLengthAllowed: false,
        isWidthAllowed: false,
        lengthFrom: 0,
        lengthTo: 0,
        widthFrom: 0,
        widthTo: 0,
        isDiameterAllowed: false,
        diameterFrom: 0,
        diameterTo: 0,
        isDisplayAsPantyTop: false,
        horizontalStartItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            },
            start: 0,
            end: 0,
        },
        verticalStartItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            },
            start: 0,
            end: 0,
        },
        verticalEndItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            },
            start: 0,
            end: 0,
        },
        horizontalEndItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            },
            start: 0,
            end: 0,
        },
    },
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        fetchBodyAreas: { status: "idle", canExecute: true },
        fetchAdditionPositions: { status: "idle", canExecute: true },
        getPositionCategory: { status: "idle", canExecute: true },
        getPrepositions: { status: "idle", canExecute: true },
    },
    command: {
        canSavePositionCategoryItem: { status: "idle", canExecute: true },
        cancelSavePositionCategory: { status: "idle", canExecute: true },
        updatePositionCategory: { status: "idle", canExecute: false },
    },
    error: {
        showErrorDialog: false,
        errorCode: null
    }
}

export const positionCategoryEditSlice = createSlice({
    name: 'positionCategory/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.actualData.name = action.payload;
            updateCanSave(state);
        },
        createNewPositionCategoryItem: (state) => {
            state.currentEditedPositionCategoryItem = createEmptyPositionCategoryItem(state);
            state.actualData.editorState = PositionCategoryEditorState.PositionCategoryItemCreate;
            filterPositionCategoryItems(state);
            updateCanSaveItem(state);
        },
        filterSelectedHorizontalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList);
        },
        filterUnselectedHorizontalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.unSelectedList);
        },
        selectHorizontalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedVerticalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.selectedList);
        },
        filterUnselectedVerticalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.unSelectedList);
        },
        selectVerticalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        toggleSelectionBodyArea: (state, action: PayloadAction<IAvailableBodyArea>) => {
            const index = state.currentEditedPositionCategoryItem.bodyAreas.findIndex(x => x.id === action.payload.id);
            if (index >= 0) {
                state.currentEditedPositionCategoryItem.bodyAreas.splice(index, 1);
            } else {
                state.currentEditedPositionCategoryItem.bodyAreas.push(action.payload);
            }
            updateCanSaveItem(state);
        },
        toggleSelectionBodySide: (state, action: PayloadAction<BodySideEnum>) => {
            const index = state.currentEditedPositionCategoryItem.bodySides.findIndex(x => x === action.payload);
            if (index >= 0) {
                state.currentEditedPositionCategoryItem.bodySides.splice(index, 1);
            } else {
                state.currentEditedPositionCategoryItem.bodySides.push(action.payload);
            }
            updateCanSaveItem(state);
        },
        cancelSavePositionCategoryItem: (state) => {
            state.currentEditedPositionCategoryItem = createEmptyPositionCategoryItem(state);
            state.actualData.editorState = PositionCategoryEditorState.PositionCategory;
        },
        savePositionCategoryItem: (state) => {
            if (state.actualData.editorState === PositionCategoryEditorState.PositionCategoryItemCreate) {
                state.actualData.positionCategoryItemSet.positionCategoryItems.push(state.currentEditedPositionCategoryItem);
                state.actualData.editorState = PositionCategoryEditorState.PositionCategory;
            }
            else {
                const indexOfItem = state.actualData.indexOfItem;
                const index = state.actualData.positionCategoryItemSet.positionCategoryItems.findIndex(x => x.sequenceId === indexOfItem);
                state.actualData.positionCategoryItemSet.positionCategoryItems[index] = state.currentEditedPositionCategoryItem;
                state.actualData.editorState = PositionCategoryEditorState.PositionCategory;
            }
            filterPositionCategoryItems(state);
            updateCanSave(state);
        },
        cancelSavePositionCategory: (state) => {
            state.command.cancelSavePositionCategory.status = "success";
        },
        handleSaveError: (state) => {
            state.command = initialState.command;
        },
        updateHorizontalStartBeginValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateHorizontalStartEndValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.end = action.payload;
            updateCanSaveItem(state);
        },
        updateVerticalStartBeginValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateVerticalStartEndValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.end = action.payload;
            updateCanSaveItem(state);
        },
        setIsDocumentAllowed: (state, action: PayloadAction<boolean>) => {
            state.currentEditedPositionCategoryItem.isDocumentAllowed = action.payload;
        },
        toggleSideSeamAllowed: (state) => {
            state.actualData.sideSeamAllowed = !state.actualData.sideSeamAllowed;
            updateCanSave(state);
        },        
        setIsLengthAllowed: (state, action: PayloadAction<boolean>) => {
            const newValue = action.payload;
            state.currentEditedPositionCategoryItem.isLengthAllowed = newValue;
            if (!newValue) {
                state.currentEditedPositionCategoryItem.lengthFrom = 0;
                state.currentEditedPositionCategoryItem.lengthTo = 0;
            }
            updateCanSaveItem(state);
        },
        setIsWidthAllowed: (state, action: PayloadAction<boolean>) => {
            const newValue = action.payload;
            state.currentEditedPositionCategoryItem.isWidthAllowed = newValue;
            if (!newValue) {
                state.currentEditedPositionCategoryItem.widthFrom = 0;
                state.currentEditedPositionCategoryItem.widthTo = 0;
            }
            updateCanSaveItem(state);
        },
        setSideSeamMode: (state, action: PayloadAction<SideSeamModeEnum>) => {
            state.currentEditedPositionCategoryItem.sideSeamModeAllowed = action.payload;
            updateCanSaveItem(state);
        },        
        setIsDiameterAllowed: (state, action: PayloadAction<boolean>) => {
            const newValue = action.payload;
            state.currentEditedPositionCategoryItem.isDiameterAllowed = newValue;
            if (!newValue) {
                state.currentEditedPositionCategoryItem.diameterFrom = 0;
                state.currentEditedPositionCategoryItem.diameterTo = 0;
            }
            updateCanSaveItem(state);
        },
        updateDiameterFrom: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.diameterFrom = action.payload;
            updateCanSaveItem(state);
        },
        updateDiameterTo: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.diameterTo = action.payload;
            updateCanSaveItem(state);
        },
        updateLengthFrom: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.lengthFrom = action.payload;
            updateCanSaveItem(state);
        },
        updateLengthTo: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.lengthTo = action.payload;
            updateCanSaveItem(state);
        },
        updateWidthFrom: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.widthFrom = action.payload;
            updateCanSaveItem(state);
        },
        updateWidthTo: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.widthTo = action.payload;
            updateCanSaveItem(state);
        },
        deletePositionCategoryItemByIndex: (state, action: PayloadAction<number>) => {
            const indexInAll = state.actualData.positionCategoryItemSet.positionCategoryItems.findIndex(x => x.sequenceId === action.payload);
            state.actualData.positionCategoryItemSet.positionCategoryItems.splice(indexInAll, 1);
            calculateNewSequenceIds(state);
            filterPositionCategoryItems(state);
            updateCanSave(state);
        },
        editPositionCategoryItemByIndex: (state, action: PayloadAction<number>) => {
            const indexInAll = action.payload;
            const itemToEdit = state.actualData.positionCategoryItemSet.positionCategoryItems.find(x => x.sequenceId === indexInAll);
            state.currentEditedPositionCategoryItem = itemToEdit;
            state.actualData.indexOfItem = indexInAll;
            state.actualData.editorState = PositionCategoryEditorState.PositionCategoryItemEdit;
        },
        filterSelectedHorizontalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.selectedList);
        },
        filterUnselectedHorizontalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.unSelectedList);
        },
        selectHorizontalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedVerticalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.selectedList);
        },
        filterUnselectedVerticalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.unSelectedList);
        },
        selectVerticalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        updateHorizontalEndFromValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateHorizontalEndToValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.end = action.payload;
            updateCanSaveItem(state);
        },
        setIsDisplayAsPantyTop: (state, action: PayloadAction<boolean>) => {
            state.currentEditedPositionCategoryItem.isDisplayAsPantyTop = action.payload;
            updateCanSaveItem(state);
        },
        filterSelectedHorizontalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList);
        },
        filterUnselectedHorizontalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.unSelectedList);
        },
        selectHorizontalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedHorizontalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.selectedList);
        },
        filterUnselectedHorizontalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.unSelectedList);
        },
        selectHorizontalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        updateVerticalEndFromValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateVerticalEndToValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.end = action.payload;
            updateCanSaveItem(state);
        },
        filterSelectedVerticalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.selectedList);
        },
        filterUnselectedVerticalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.unSelectedList);
        },
        selectVerticalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedVerticalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.selectedList);
        },
        filterUnselectedVerticalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.unSelectedList);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.positionCategoryItemSet.filter = action.payload;
            filterPositionCategoryItems(state);
        },
        selectVerticalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
    }, extraReducers: (builder) => {
        // fetch bodyAreas
        builder.addCase(fetchBodyAreas.pending, (state, action) => {
            state.query.fetchBodyAreas.status = "pending"
            state.query.fetchBodyAreas.canExecute = false;
        }).addCase(fetchBodyAreas.rejected, (state, action) => {
            state.query.fetchBodyAreas.status = "error"
            state.query.fetchBodyAreas.message = action.error.message;
            state.query.fetchBodyAreas.canExecute = true;
        }).addCase(fetchBodyAreas.fulfilled, (state, action) => {
            state.query.fetchBodyAreas.status = "success"
            state.query.fetchBodyAreas.message = undefined;
            state.query.fetchBodyAreas.canExecute = true;
            state.loadedData.bodyAreas = action.payload.getData();

            //fetchAdditionPositions
        }).addCase(fetchAdditionPositions.pending, (state, action) => {
            state.query.fetchAdditionPositions.status = "pending"
            state.query.fetchAdditionPositions.canExecute = false;
        }).addCase(fetchAdditionPositions.rejected, (state, action) => {
            state.query.fetchAdditionPositions.status = "error"
            state.query.fetchAdditionPositions.message = action.error.message;
            state.query.fetchAdditionPositions.canExecute = true;
        }).addCase(fetchAdditionPositions.fulfilled, (state, action) => {
            state.query.fetchAdditionPositions.status = "success"
            state.query.fetchAdditionPositions.message = undefined;
            state.query.fetchAdditionPositions.canExecute = true;

            const additionPositions = action.payload.getData();
            const horizontalStartAdditionPositions = AdditionPositionFilterHelper.FilterHorizontalStart(additionPositions);
            state.loadedData.horizontalStartAdditionPositions = horizontalStartAdditionPositions;

            const horizontalEndAdditionPositions = AdditionPositionFilterHelper.FilterHorizontalEnd(additionPositions);
            state.loadedData.horizontalEndAdditionPositions = horizontalEndAdditionPositions;

            const verticalStartAdditionPositions = AdditionPositionFilterHelper.FilterVerticalStart(additionPositions);
            state.loadedData.verticalStartAdditionPositions = verticalStartAdditionPositions;

            const verticalEndAdditionPositions = AdditionPositionFilterHelper.FilterVerticalEnd(additionPositions);
            state.loadedData.verticalEndAdditionPositions = verticalEndAdditionPositions;

            //getPrepositions
        }).addCase(getPrepositions.pending, (state, action) => {
            state.query.getPrepositions.status = "pending"
            state.query.getPrepositions.canExecute = false;
        }).addCase(getPrepositions.rejected, (state, action) => {
            state.query.getPrepositions.status = "error"
            state.query.getPrepositions.message = action.error.message;
            state.query.getPrepositions.canExecute = true;
        }).addCase(getPrepositions.fulfilled, (state, action) => {
            state.query.getPrepositions.status = "success"
            state.query.getPrepositions.message = undefined;
            state.query.getPrepositions.canExecute = true;
            const prepositions = action.payload.getData();
            state.loadedData.prepositions = prepositions.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    translationKeyForName: x.translationKeyForName
                }
            });

            //fetchAllData
        }).addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = "pending"
            state.query.fetchAllData.canExecute = false;
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
            state.query.fetchAllData.canExecute = true;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success"
            state.query.fetchAllData.message = undefined;
            state.query.fetchAllData.canExecute = true;

            //update
        }).addCase(updatePositionCategory.pending, (state, action) => {
            state.command.updatePositionCategory.status = "pending"
            state.command.updatePositionCategory.canExecute = false;
        }).addCase(updatePositionCategory.rejected, (state, action) => {
            state.command.updatePositionCategory.status = "error"
            state.command.updatePositionCategory.canExecute = true;
            state.command.updatePositionCategory.message = action.error.message;

            if (action.error.code === "422" || action.error.code === "400") {
                state.error.showErrorDialog = true;
                state.error.errorCode = action.error.code
            } else {
                state.error.showErrorDialog = false;
                state.error.errorCode = null;
            }
        }).addCase(updatePositionCategory.fulfilled, (state, action) => {
            state.command.updatePositionCategory.status = "success"
            state.command.updatePositionCategory.message = undefined;
            state.command.updatePositionCategory.canExecute = true;


            //getPositionCategory
        }).addCase(getPositionCategory.pending, (state, action) => {
            state.query.getPositionCategory.status = "pending"
            state.query.getPositionCategory.canExecute = false;
        }).addCase(getPositionCategory.rejected, (state, action) => {
            state.query.getPositionCategory.status = "error"
            state.query.getPositionCategory.canExecute = true;
            state.query.getPositionCategory.message = action.error.message;
        }).addCase(getPositionCategory.fulfilled, (state, action) => {
            state.query.getPositionCategory.status = "success"
            state.query.getPositionCategory.message = undefined;
            state.query.getPositionCategory.canExecute = true;

            const positionCategory = action.payload.getData();
            state.loadedData.positionCategoryId = positionCategory.id;
            state.actualData.name = positionCategory.name;
            state.actualData.sideSeamAllowed = positionCategory.sideSeamAllowed;
            const positionCategoryItems: IPositionCategoryItem[] = positionCategory.positionItems.map(x => {
                let positionCategoryItem = createEmptyPositionCategoryItem(state);
                positionCategoryItem.isDocumentAllowed = x.isDocumentAllowed;
                positionCategoryItem.isDiameterAllowed = x.isDiameterAllowed;
                positionCategoryItem.diameterFrom = x.diameterFrom;
                positionCategoryItem.diameterTo = x.diameterTo;
                positionCategoryItem.isLengthAllowed = x.isLengthAllowed;
                positionCategoryItem.lengthFrom = x.lengthFrom;
                positionCategoryItem.lengthTo = x.lengthTo;
                positionCategoryItem.isWidthAllowed = x.isWidthAllowed;
                positionCategoryItem.widthFrom = x.widthFrom;
                positionCategoryItem.widthTo = x.widthTo;
                positionCategoryItem.isDisplayAsPantyTop = x.displayAsPantyTop;
                positionCategoryItem.sideSeamModeAllowed = x.sideSeamModeAllowed ?? SideSeamModeEnum.Normal;
                x.positionItemBodyAreas.forEach(y => {
                    const bodyArea = state.loadedData.bodyAreas.find(z => z.id === y.bodyAreaId);
                    positionCategoryItem.bodyAreas.push(bodyArea);
                });
                x.positionItemBodySides.forEach(y => {
                    positionCategoryItem.bodySides.push(y.bodySide);
                });
                x.positionItemDirections.forEach(y => {
                    if (y.additionHierarchyId === 1) {
                        const additionPositionIds = y.positionItemAdditions.map(x => x.additionPositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.horizontalStartItem.additionPositionSelection, additionPositionIds);
                        
                        filterSelectionList(positionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList);
                        filterSelectionList(positionCategoryItem.horizontalStartItem.additionPositionSelection.unSelectedList);
                        positionCategoryItem.horizontalStartItem.start = y.start;
                        positionCategoryItem.horizontalStartItem.end = y.end;

                        const prepositionIds = y.positionItemPrepositions.map(x => x.prepositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.horizontalStartItem.prepositionSelection, prepositionIds);
                        
                        filterSelectionList(positionCategoryItem.horizontalStartItem.prepositionSelection.unSelectedList);
                        filterSelectionList(positionCategoryItem.horizontalStartItem.prepositionSelection.selectedList);

                    }
                    else if (y.additionHierarchyId === 2) {
                        const additionPositionIds = y.positionItemAdditions.map(x => x.additionPositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.verticalStartItem.additionPositionSelection, additionPositionIds);
                        
                        filterSelectionList(positionCategoryItem.verticalStartItem.additionPositionSelection.selectedList);
                        filterSelectionList(positionCategoryItem.verticalStartItem.additionPositionSelection.unSelectedList);
                        positionCategoryItem.verticalStartItem.start = y.start;
                        positionCategoryItem.verticalStartItem.end = y.end;

                        const ids = y.positionItemPrepositions.map(x=> x.prepositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.verticalStartItem.prepositionSelection, ids);
                        
                        filterSelectionList(positionCategoryItem.verticalStartItem.prepositionSelection.unSelectedList);
                        filterSelectionList(positionCategoryItem.verticalStartItem.prepositionSelection.selectedList);
                    }
                    else if (y.additionHierarchyId === 3) {
                        const additionPositionIds = y.positionItemAdditions.map(x=> x.additionPositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.horizontalEndItem.additionPositionSelection, additionPositionIds);

                        filterSelectionList(positionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList);
                        filterSelectionList(positionCategoryItem.horizontalEndItem.additionPositionSelection.unSelectedList);
                        positionCategoryItem.horizontalEndItem.start = y.start;
                        positionCategoryItem.horizontalEndItem.end = y.end;

                        const prepositionIds = y.positionItemPrepositions.map(x=> x.prepositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.horizontalEndItem.prepositionSelection, prepositionIds);
    
                        filterSelectionList(positionCategoryItem.horizontalEndItem.prepositionSelection.unSelectedList);
                        filterSelectionList(positionCategoryItem.horizontalEndItem.prepositionSelection.selectedList);
                    }
                    else if (y.additionHierarchyId === 4) {

                        const additionPositionIds = y.positionItemAdditions.map(x=> x.additionPositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.verticalEndItem.additionPositionSelection, additionPositionIds);

                        filterSelectionList(positionCategoryItem.verticalEndItem.additionPositionSelection.selectedList);
                        filterSelectionList(positionCategoryItem.verticalEndItem.additionPositionSelection.unSelectedList);
                        positionCategoryItem.verticalEndItem.start = y.start;
                        positionCategoryItem.verticalEndItem.end = y.end;

                        const prepositionIds = y.positionItemPrepositions.map(x=> x.prepositionId);
                        moveFromUnselectedToSelected(positionCategoryItem.verticalEndItem.prepositionSelection, prepositionIds);

                        filterSelectionList(positionCategoryItem.verticalEndItem.prepositionSelection.unSelectedList);
                        filterSelectionList(positionCategoryItem.verticalEndItem.prepositionSelection.selectedList);
                    }
                });
                return positionCategoryItem;
            });
            state.actualData.positionCategoryItemSet.positionCategoryItems = positionCategoryItems;
            calculateNewSequenceIds(state);
            filterPositionCategoryItems(state);
            state.loadedData.positionCategory = state.actualData;
        });
    }
})

export const {
    updateName,
    resetState,
    createNewPositionCategoryItem,
    filterSelectedHorizontalAdditionPositions,
    filterUnselectedHorizontalAdditionPositions,
    unselectHorizontalAdditionPositions,
    selectHorizontalAdditionPositions,
    filterSelectedVerticalAdditionPositions,
    filterUnselectedVerticalAdditionPositions,
    unselectVerticalAdditionPositions,
    selectVerticalAdditionPositions,
    toggleSelectionBodyArea,
    toggleSelectionBodySide,
    cancelSavePositionCategoryItem,
    savePositionCategoryItem,
    cancelSavePositionCategory,
    handleSaveError,
    updateHorizontalStartBeginValue,
    updateHorizontalStartEndValue,
    updateVerticalStartBeginValue,
    updateVerticalStartEndValue,
    setIsDocumentAllowed,
    setIsLengthAllowed,
    setIsWidthAllowed,
    setIsDiameterAllowed,
    updateDiameterTo,
    updateDiameterFrom,
    updateLengthFrom,
    updateLengthTo,
    updateWidthFrom,
    updateWidthTo,
    toggleSideSeamAllowed,
    deletePositionCategoryItemByIndex,
    editPositionCategoryItemByIndex,
    filterSelectedHorizontalStartPrepositions,
    filterUnselectedHorizontalStartPrepositions,
    filterSelectedVerticalStartPrepositions,
    filterUnselectedVerticalStartPrepositions,
    selectHorizontalStartPrepositions,
    unselectHorizontalStartPrepositions,
    selectVerticalStartPrepositions,
    unselectVerticalStartPrepositions,
    updateHorizontalEndFromValue,
    updateHorizontalEndToValue,
    filterSelectedHorizontalEndAdditionPositions,
    filterUnselectedHorizontalEndAdditionPositions,
    selectHorizontalEndAdditionPositions,
    unselectHorizontalEndAdditionPositions,
    filterSelectedHorizontalEndPrepositions,
    filterUnselectedHorizontalEndPrepositions,
    selectHorizontalEndPrepositions,
    unselectHorizontalEndPrepositions,
    updateVerticalEndFromValue,
    updateVerticalEndToValue,
    filterSelectedVerticalEndAdditionPositions,
    filterUnselectedVerticalEndAdditionPositions,
    selectVerticalEndAdditionPositions,
    unselectVerticalEndAdditionPositions,
    filterSelectedVerticalEndPrepositions,
    filterUnselectedVerticalEndPrepositions,
    selectVerticalEndPrepositions,
    unselectVerticalEndPrepositions,
    setGridFilters,
    setSideSeamMode,
    setIsDisplayAsPantyTop,
} = positionCategoryEditSlice.actions

export default positionCategoryEditSlice.reducer