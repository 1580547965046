import { Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { FlexBox, PrimaryButtonComponent, Right } from "shared/shared";
import { EditorBoxStyled } from "./list_editor.style";
import { ListEditorSummaryComponent } from "./list_editor_summary.component";
import { ReactNode } from "react";
import { ListEditorMode } from "./enums/EditorMode";
import { EditorSummaryDefinition } from "./models/EditorSummaryDefinition";

interface ListEditorProps {
    addButtonText: string;
    onNewConfiguration: () => {};
    onEditConfiguration: (index: number) => {};
    onDeleteConfiguration: (index: number) => {};
    isValid?: (index: number) => boolean;
    editableConfigurationIndex: number;
    editorMode: ListEditorMode;
    renderConfiguration: () => ReactNode;
    editorSummaryDefinitions: EditorSummaryDefinition[]
    items: Array<any>;
    isNested?: boolean;
}

export const ListEditorComponent = (props: ListEditorProps) => {
    const isSummaryWithItem = props.items.length > 0;

    return (
        <Grid container
            direction="column"
            spacing={3}>
            <Grid item>
                <FlexBox>
                    <Right>
                        <PrimaryButtonComponent
                            disabled={props.editorMode !== ListEditorMode.None}
                            onClick={props.onNewConfiguration}>
                            <AddIcon />{props.addButtonText}
                        </PrimaryButtonComponent>
                    </Right>
                </FlexBox>
            </Grid>
            <Grid item>
                <EditorBoxStyled>
                    {props.editorMode === ListEditorMode.New && props.renderConfiguration()}
                    {isSummaryWithItem && <ListEditorSummaryComponent
                        editorSummaryDefinitions={props.editorSummaryDefinitions}
                        items={props.items}
                        renderConfiguration={props.renderConfiguration}
                        onEdit={props.onEditConfiguration}
                        onDelete={props.onDeleteConfiguration}
                        isValid={props.isValid}
                        isNested={props.isNested}
                        editorMode={props.editorMode}
                        editableConfigurationIndex={props.editableConfigurationIndex} />}
                </EditorBoxStyled>
            </Grid>
        </Grid>
    )
};