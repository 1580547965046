import React, { useState } from 'react';
import { ProductConfigurationViewModel } from '../../../../models/simulator/product_configuration';
import { AccordionModel } from 'models/accordion.model';
import * as style from './product_configurations_accordion.style';
import { ProductConfigurationsAccordionComponentItem } from './product_configurations_accordion_item.component';
import { FlexBox, Left, Right } from 'shared/shared';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import { changePageSize, nextPage, previousPage, setSearchFilter } from '../redux/product_configurations_overview.slice';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

type ProductConfigurationsAccordionComponentProps = {
    productConfigurations: ProductConfigurationViewModel[];
};

export const ProductConfigurationsAccordionComponent: React.FC<ProductConfigurationsAccordionComponentProps> = ({ productConfigurations }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.simulation_productconfigurations_overview);

    const [expandedIndex, setExpandedIndex] = useState(-1);

    const disablePreviousButton = state.actualData.currentPageIndex === 0;
    const disableNextButton = (state.actualData.currentPageIndex + 1) === state.actualData.productConfigurationPages.length;

    const toggleExpandedProductConfiguration = (index: number) => {
        setExpandedIndex(expandedIndex === index ? -1 : index);
    }

    const handlePageSizeChange = (
        event: React.MouseEvent<HTMLElement>,
        newPageSize: number) => {
        dispatch(changePageSize(newPageSize))
    }
    return (
        <div>
            <FlexBox>
                <Left>
                    <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="Konfigurationen suchen ..." />
                </Left>
                <Right>
                    <FlexBox>
                        <ToggleButtonGroup
                            color="primary"
                            value={state.actualData.pageSize}
                            exclusive
                            onChange={handlePageSizeChange}
                            aria-label="Platform">
                            <ToggleButton value={10}>10</ToggleButton>
                            <ToggleButton value={50}>50</ToggleButton>
                            <ToggleButton value={100}>100</ToggleButton>
                        </ToggleButtonGroup>
                        <IconButton
                            style={{ outline: "none" }}
                            disabled={disablePreviousButton}
                            onClick={() => { setExpandedIndex(-1); dispatch(previousPage()) }}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        <div style={{ userSelect: "none" }}>{state.actualData.currentPageIndex + 1} von {state.actualData.productConfigurationPages.length}</div>
                        <IconButton
                            style={{ outline: "none" }}
                            disabled={disableNextButton}
                            onClick={() => { setExpandedIndex(-1); dispatch(nextPage()) }}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </FlexBox>
                </Right>
            </FlexBox>
            <style.Headline>
                <style.HeaderIconColumn></style.HeaderIconColumn>
                <style.HeaderColumn>ID</style.HeaderColumn>
                <style.HeaderColumn>Erfasst durch</style.HeaderColumn>
                <style.HeaderColumn>Letzte Aktivität</style.HeaderColumn>
                <style.HeaderColumn>Schreibgeschützt</style.HeaderColumn>
            </style.Headline>
            {
                (!productConfigurations || productConfigurations.length === 0) ?
                    <div>Keine passenden Ergebnisse gefunden</div>
                    :
                    productConfigurations.map((productConfiguration, index) =>
                        <ProductConfigurationsAccordionComponentItem
                            key={index}
                            index={index}
                            isExpanded={index === expandedIndex}
                            toggleExpandedProductConfiguration={toggleExpandedProductConfiguration}
                            productConfiguration={productConfiguration} />)
            }
        </div>
    );
}