import { configureStore } from '@reduxjs/toolkit';
import missingMasspointsDialogReducer from 'shared/components/dialogs/missing_masspoints/redux/missing_masspoints.slice';
import additionReducer from '../additions/addition/redux/addition.slice';
import additionOverviewReducer from '../additions/addition/addition_overview/redux/addition_overview.slice';
import additionCategoryOverviewReducer from '../additions/addition_category/addition_categories_overview/redux/addition_category_overview.slice';
import additionCategoryReducer from '../additions/addition_category/redux/addition_category.slice';
import characteristicCategoriesOverviewReducer from '../additions/characteristic_category/characteristic_category_overview/redux/characteristic_category_overview.slice';
import characteristicCategoriesCreateReducer from '../additions/characteristic_category/characteristic_category_create/redux/characteristic_category_create.slice';
import characteristicCategoriesEditReducer from '../additions/characteristic_category/characteristic_category_edit/redux/characteristic_category_edit.slice';
import characteristicTypesOverviewReducer from '../additions/characteristic_type/characteristic_type_overview/redux/characteristic_type_overview.slice';
import characteristicTypeCreateReducer from '../additions/characteristic_type/characteristic_type_create/redux/characteristic_type_create.slice';
import characteristicTypeEditReducer from '../additions/characteristic_type/characteristic_type_edit/redux/characteristic_type_edit.slice';
import baseDataImportsReducer from '../admin/basedataimports/redux/basedataimports.slice';
import MassimageOverviewReducer from '../admin/massimages/overview/redux/massimages_overview.slice';
import massimageConfigurationCreateReducer from '../admin/massimages/config/config_create/redux/create_config.slice';
import massimageConfigurationUpdateReducer from '../admin/massimages/config/config_edit/redux/update_config.slice';
import UpdateMassImageReducer from '../admin/massimages/update/redux/update_massimage.slice';
import checkCreateReducer from '../check/check_create/redux/check_create.slice';
import checkDuplicateReducer from '../check/check_duplicate/redux/check_duplicate.slice';
import checkEditReducer from '../check/check_edit/redux/check_edit.slice';
import plausibilityCheckGetReduce from '../check/check_overview/redux/check_overview.slice';
import plausibilityCheckTypeCreateReducer from '../check/type_create/redux/type_create.slice';
import plausibilityCheckTypeEditReducer from '../check/type_edit/redux/type_edit.slice';
import plausibilityCheckTypeGetReduce from '../check/type_overview/redux/type_overview.slice';
import additionHistoryOverviewSlice from '../history/addition_history/redux/addition_history.slice';
import additionHistoryDetailsSlice from '../history/addition_history_details/redux/addition_history_details.slice';
import checktypesHistoryOverviewReducer from '../history/checktype_history/redux/checktype_history.slice';
import checksHistoryOverviewReducer from '../history/check_history/redux/check_history.slice';
import checksHistoryDetailsReducer from '../history/check_history_details/redux/check_history_details.slice';
import massMasksHistoryOverviewReducer from '../history/massmask_history/redux/massmask_history.slice';
import massMasksHistoryDetailReducer from '../history/massmask_history_details/redux/massmask_history_details.slice';
import massPointsHistoryOverviewReducer from '../history/masspoint_history/redux/masspoint_history.slice';
import masspointHistoryDetailsReducer from '../history/masspoint_history_details/redux/masspoint_history_details.slice';
import footOptionsCreateReducer from "../massmasks/footoptions_create/redux/footoptions.slice";
import footOptionsEditReducer from "../massmasks/footoptions_edit/redux/footoption_edit.slice";
import footOptionsOverviewReducer from "../massmasks/footoptions_overview/redux/footoptions_overview.slice";
import massMaskCommonInfoCreateReducer from '../massmasks/massmask_common_info_create/redux/massmask_common_info_create.slice';
import massMaskCommonInfoEditReducer from '../massmasks/massmask_common_info_edit/redux/massmask_common_info_edit.slice';
import massMaskMassDefinitionReducer from '../massmasks/massmask_massdefinition/redux/massmask_massdefinition.slice';
import massMaskMassImageReducer from '../massmasks/massmask_massimage/redux/massmask_massimage.slice';
import massMaskMassPointsReducer from '../massmasks/massmask_masspoints/redux/massmask_masspoints.slice';
import massMasksOverviewReducer from '../massmasks/massmask_overview/redux/massmasks_overview.slice';
import oneLegCreateReducer from "../massmasks/onelegs_create/redux/onelegs_create.slice";
import oneLegEditReducer from "../massmasks/onelegs_edit/redux/oneleg_edit.slice";
import oneLegOverviewReducer from "../massmasks/onelegs_overview/redux/onelegs_overview.slice";
import masspointCreateReducer from '../masspoints/masspoint_create/redux/masspoint_create.slice';
import masspointGroupOverviewReducer from '../masspoints/masspoint_groups/masspoint_group_overview/redux/masspoint_group_overview.slice';
import masspointGroupReducer from '../masspoints/masspoint_groups/masspoint_group/redux/masspoint_group.slice';
import masspointEditReducer from '../masspoints/masspoint_edit/redux/masspoint_edit.slice';
import masspointEditReferencesReducer from '../masspoints/edit_code_references/redux/edit_references.slice';
import MasspointOverviewReducer from '../masspoints/masspoint_overview/redux/masspoint_overview.slice';
import rulesFormulaReducer from '../rules_formula/redux/rules_formula.slice';
import rulesInfoReducer from '../rules_info/redux/rules_info.slice';
import rulesMinMaxReducer from '../rules_min_max/redux/rules_min_max.slice';
import simulationMassMaskSelectionReducer from '../simulator/massmask_selection/redux/massmask_selection.slice';
import simulationProductConfigurationsOverviewReducer from '../simulator/product_configurations/overview/redux/product_configurations_overview.slice';
import simulatorReducer from '../simulator/simulator_simulation/redux/simulator_simulation.slice';
import productionInstructionOverviewReducer from 'additions/production_instruction/production_instruction_overview/redux/production_instruction_overview.slice';
import additionPositionOverviewReducer from 'additions/addition_position/addition_position_overview/redux/addition_position_overview.slice';
import additionPositionCreateReducer from 'additions/addition_position/addition_position_create/redux/addition_position_create.slice';
import additionPositionEditReducer from 'additions/addition_position/addition_position_edit/redux/addition_position_edit.slice';
import positionCategoriesOverviewReducer from 'additions/position_categories/position_categories_overview/redux/position_category_overview.slice';
import positionCategoriesCreateReducer from 'additions/position_categories/position_category_create/redux/position_category_create.slice';
import positionCategoryItemDetailReducer from 'additions/position_categories/position_category_item_details/redux/position_category_item_details.slice';
import positionCategoriesEditReducer from 'additions/position_categories/position_category_edit/redux/position_category_edit.slice';
import policyOverviewReducer from 'check/policy/policy_overview/redux/policy_overview.slice';
import policyMandatoryCategoryCreateReducer from 'check/policy/policy_mandatory_category/policy_mandatory_category_create/redux/policy_mandatory_category_create.slice';
import policyMandatoryCategoryUpdateReducer from 'check/policy/policy_mandatory_category/policy_mandatory_category_update/redux/policy_mandatory_category_update.slice';
import policyMandatoryCategoryByMasspointCreateReducer from 'check/policy/policy_mandatory_category_by_masspoint/policy_mandatory_category_by_masspoint_create/redux/policy_mandatory_category_by_masspoint_create.slice';
import policyMandatoryCategoryByMasspointUpdateReducer from 'check/policy/policy_mandatory_category_by_masspoint/policy_mandatory_category_by_masspoint_update/redux/policy_mandatory_category_by_masspoint_update.slice';
import policySoftSeamCreateReducer from 'check/policy/policy_soft_seam/policy_soft_seam_create/redux/policy_soft_seam_create.slice';
import policySoftSeamUpdateReducer from 'check/policy/policy_soft_seam/policy_soft_seam_update/redux/policy_soft_seam_update.slice';
import policyAdditionRankCreateReducer from 'check/policy/policy_addition_rank/policy_addition_rank_create/redux/policy_addition_rank_create.slice';
import policyDefaultAdditionByArticleTypeCreateReducer from 'check/policy/policy_default_addition/policy_default_addition_by_articletype_create/redux/policy_default_addition_by_articletype_create.slice';
import policyDefaultAdditionByArticleTypeUpdateReducer from 'check/policy/policy_default_addition/policy_default_addition_by_articletype_update/redux/policy_default_addition_by_articletype_update.slice';
import policyMandatoryByAttributeCreateReducer from 'check/policy/policy_mandatory_by_attribute/policy_mandatory_by_attribute_create/redux/policy_mandatory_by_attribute_create.slice';
import policyMandatoryByAttributeUpdateReducer from 'check/policy/policy_mandatory_by_attribute/policy_mandatory_by_attribute_update/redux/policy_mandatory_by_attribute_update.slice';
import policyDefaultAdditionByQualityAndAttributeCreateReducer from 'check/policy/policy_default_addition_by_quality_and_attribute/policy_default_addition_by_quality_and_attribute_create/redux/policy_default_addition_by_qu_and_att_create.slice';
import policyDefaultAdditionByQualityAndAttributeUpdateReducer from 'check/policy/policy_default_addition_by_quality_and_attribute/policy_default_addition_by_quality_and_attribute_update/redux/policy_default_addition_by_qu_and_att_update.slice';
import policyExcludeAdditionByMasspointCreateReducer from 'check/policy/policy_exclude_addition/policy_exclude_addition_by_masspoint_create/redux/policy_exclude_addition_by_masspoint_create.slice';
import policyExcludeAdditionByMasspointUpdateReducer from 'check/policy/policy_exclude_addition/policy_exclude_addition_by_masspoint_update/redux/policy_exclude_addition_by_masspoint_update.slice';
import policyUpperThresholdCreateReducer from 'check/policy/policy_upper_threshold/policy_upper_threshold_create/redux/policy_upper_threshold_create.slice';
import policyUpperThresholdUpdateReducer from 'check/policy/policy_upper_threshold/policy_upper_threshold_update/redux/policy_upper_threshold_update.slice';
import policyExcludeAdditionByMasspointValueCreateReducer from 'check/policy/policy_exclude_addition_by_masspoint_value/policy_exclude_addition_by_masspoint_value_create/redux/policy_exclude_addition_by_masspoint_value_create.slice';
import policyExcludeAdditionByMasspointValueUpdateReducer from 'check/policy/policy_exclude_addition_by_masspoint_value/policy_exclude_addition_by_masspoint_value_update/redux/policy_exclude_addition_by_masspoint_value_update.slice';
import policyAdjustRangesByMasspointCreateReducer from 'check/policy/policy_adjust_ranges_by_masspoint/policy_adjust_ranges_by_masspoint_create/redux/policy_adjust_ranges_by_masspoint_create.slice';
import policyAdjustRangesByMasspointUpdateReducer from 'check/policy/policy_adjust_ranges_by_masspoint/policy_adjust_ranges_by_masspoint_update/redux/policy_adjust_ranges_by_masspoint_update.slice';
import policyAdditionRankUpdateReducer from 'check/policy/policy_addition_rank/policy_addition_rank_update/redux/policy_addition_rank_update.slice';
import policySideSeamReducer from 'check/policy/policy_side_seam/redux/policy_side_seam.slice';
import policyScarTreatmentReducer from 'check/policy/policy_scar_treatment/redux/policy_scar_treatment.slice';
import policyFingersAndToesReducer from 'check/policy/policy_fingers_and_toes/redux/policy_fingers_and_toes.slice';
import bodyInformationOverviewSliceReducer from 'massmasks/bodyinformation/bodyinformation_overview/redux/bodyinformation_overview.slice';
import bodyInformationCreateSliceReducer from 'massmasks/bodyinformation/bodyinformation_create/redux/bodyinformation_create.slice';
import bodyInformationEditSliceReducer from 'massmasks/bodyinformation/bodyinformation_edit/redux/bodyinformation_edit.slice';
import serialSizeOverviewSliceReducer from 'masspoints/serial_sizes/serial_sizes_overview/redux/serial_sizes_overview.slice';
import serialSizePreviewSliceReducer from 'masspoints/serial_sizes/serial_sizes_preview/redux/serial_size_preview.slice';
import serialSizeCreateSliceReducer from 'masspoints/serial_sizes/serial_sizes_create/redux/serial_sizes_create.slice';
import serialSizeUpdateSliceReducer from 'masspoints/serial_sizes/serial_sizes_edit/redux/serial_sizes_edit.slice';
import positionMappingSliceReducer from 'admin/positionmapping/redux/position_mapping.slice';
import productionInstructionSlice from "../additions/production_instruction/production_instruction/redux/production_instruction.slice";
import policyAdjustMaximumRangeCreateReducer from 'check/policy/policy_adjust_maximum_range/policy_adjust_maximum_range_create/redux/policy_adjust_maximum_range_create.slice';
import policyAdjustMaximumRangeEditReducer from 'check/policy/policy_adjust_maximum_range/policy_adjust_maximum_range_update/redux/policy_adjust_maximum_range_update.slice';

export const reduxStore = configureStore({
    reducer: {
        addition_category_overview: additionCategoryOverviewReducer,
        addition_category: additionCategoryReducer,
        addition_overview: additionOverviewReducer,
        addition_history_overview: additionHistoryOverviewSlice,
        addition_history_details: additionHistoryDetailsSlice,
        addition: additionReducer,
        characteristic_categories_overview: characteristicCategoriesOverviewReducer,
        characteristic_categories_create: characteristicCategoriesCreateReducer,
        characteristic_categories_edit: characteristicCategoriesEditReducer,
        characteristic_types_overview: characteristicTypesOverviewReducer,
        characteristic_types_create: characteristicTypeCreateReducer,
        characteristic_types_edit: characteristicTypeEditReducer,
        production_instruction_overview: productionInstructionOverviewReducer,
        production_instruction: productionInstructionSlice,
        simulation: simulatorReducer,
        simulation_productconfigurations_overview: simulationProductConfigurationsOverviewReducer,
        simulation_massmask_selection: simulationMassMaskSelectionReducer,
        rules_info: rulesInfoReducer,
        rules_min_max: rulesMinMaxReducer,
        rules_formula: rulesFormulaReducer,
        check_duplicate: checkDuplicateReducer,
        check_edit: checkEditReducer,
        checks_history_overview: checksHistoryOverviewReducer,
        checks_history_details: checksHistoryDetailsReducer,
        masspoint_group_overviews: masspointGroupOverviewReducer,
        masspoint_groups: masspointGroupReducer,
        masspoint_overviews: MasspointOverviewReducer,
        masspoint_create: masspointCreateReducer,
        masspoint_edit: masspointEditReducer,
        masspoint_edit_references: masspointEditReferencesReducer,
        plausibilitychecktype_edit: plausibilityCheckTypeEditReducer,
        massmasks_overview: massMasksOverviewReducer,
        massmask_common_info_create: massMaskCommonInfoCreateReducer,
        massmask_common_info_edit: massMaskCommonInfoEditReducer,
        massmask_massdefinition: massMaskMassDefinitionReducer,
        massmask_massimage: massMaskMassImageReducer,
        massmask_masspoints: massMaskMassPointsReducer,
        foot_options_create: footOptionsCreateReducer,
        foot_options_edit: footOptionsEditReducer,
        foot_options_overview: footOptionsOverviewReducer,
        one_leg_overview: oneLegOverviewReducer,
        one_leg_create: oneLegCreateReducer,
        one_leg_edit: oneLegEditReducer,
        massmasks_history_overview: massMasksHistoryOverviewReducer,
        massmasks_history_details: massMasksHistoryDetailReducer,
        masspoints_history_overview: massPointsHistoryOverviewReducer,
        massimage_overviews: MassimageOverviewReducer,
        massimage_configuration_file_create: massimageConfigurationCreateReducer,
        massimage_configuration_file_update: massimageConfigurationUpdateReducer,
        update_massimage: UpdateMassImageReducer,
        plausibilitychecktype_create: plausibilityCheckTypeCreateReducer,
        plausibilitychecktype_get: plausibilityCheckTypeGetReduce,
        plausibilitycheck_get: plausibilityCheckGetReduce,
        checktypes_history_overview: checktypesHistoryOverviewReducer,
        check_create: checkCreateReducer,
        masspoint_history_details: masspointHistoryDetailsReducer,
        missing_masspoints_dialog: missingMasspointsDialogReducer,
        basedata_imports: baseDataImportsReducer,
        addition_position_overview: additionPositionOverviewReducer,
        addition_position_create: additionPositionCreateReducer,
        addition_position_edit: additionPositionEditReducer,
        policy_overview: policyOverviewReducer,
        policy_mandatory_category_create: policyMandatoryCategoryCreateReducer,
        policy_mandatory_category_update: policyMandatoryCategoryUpdateReducer,
        policy_mandatory_category_by_masspoint_create: policyMandatoryCategoryByMasspointCreateReducer,
        policy_mandatory_category_by_masspoint_update: policyMandatoryCategoryByMasspointUpdateReducer,
        policy_soft_seam_create: policySoftSeamCreateReducer,
        policy_soft_seam_update: policySoftSeamUpdateReducer,
        policy_side_seam: policySideSeamReducer,
        policy_scar_treatment: policyScarTreatmentReducer,
        policy_fingers_and_toes: policyFingersAndToesReducer,
        policy_addition_rank_create: policyAdditionRankCreateReducer,
        policy_addition_rank_update: policyAdditionRankUpdateReducer,
        policy_default_addition_by_articletype_create: policyDefaultAdditionByArticleTypeCreateReducer,
        policy_default_addition_by_articletype_update: policyDefaultAdditionByArticleTypeUpdateReducer,
        policy_mandatory_by_attribute_create: policyMandatoryByAttributeCreateReducer,
        policy_mandatory_by_attribute_update: policyMandatoryByAttributeUpdateReducer,
        policy_default_addition_by_quality_and_attribute_create: policyDefaultAdditionByQualityAndAttributeCreateReducer,
        policy_default_addition_by_quality_and_attribute_update: policyDefaultAdditionByQualityAndAttributeUpdateReducer,
        policy_exclude_addition_by_masspoint_create: policyExcludeAdditionByMasspointCreateReducer,
        policy_exclude_addition_by_masspoint_update: policyExcludeAdditionByMasspointUpdateReducer,
        policy_upper_threshold_create: policyUpperThresholdCreateReducer,
        policy_upper_threshold_update: policyUpperThresholdUpdateReducer,
        policy_exclude_addition_by_masspoint_value_create: policyExcludeAdditionByMasspointValueCreateReducer,
        policy_exclude_addition_by_masspoint_value_update: policyExcludeAdditionByMasspointValueUpdateReducer,
        policy_adjust_ranges_by_masspoint_create: policyAdjustRangesByMasspointCreateReducer,
        policy_adjust_ranges_by_masspoint_update: policyAdjustRangesByMasspointUpdateReducer,
        policy_adjust_maximum_range_create: policyAdjustMaximumRangeCreateReducer,
        policy_adjust_maximum_range_edit: policyAdjustMaximumRangeEditReducer,
        position_categories_overview: positionCategoriesOverviewReducer,
        position_category_create: positionCategoriesCreateReducer,
        position_category_edit: positionCategoriesEditReducer,
        position_category_item_details: positionCategoryItemDetailReducer,
        body_information_overview: bodyInformationOverviewSliceReducer,
        body_information_create: bodyInformationCreateSliceReducer,
        body_information_edit: bodyInformationEditSliceReducer,
        serial_size_overview: serialSizeOverviewSliceReducer,
        serial_sizes_preview: serialSizePreviewSliceReducer,
        serial_size_create: serialSizeCreateSliceReducer,
        serial_size_edit: serialSizeUpdateSliceReducer,
        position_mapping: positionMappingSliceReducer
    },
})

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch