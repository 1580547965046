import { AdditionPositionCreateState } from "./addition_position_create.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filterMasspoints, moveFromSelectedToUnselected, moveFromUnselectedToSelected, updateCanSave } from "./addition_position_create.reducer";
import { fetchAllData, getAdditionHierarchies, getAdditionPositionTranslations, getMassPoints, saveAdditionPosition } from "./addition_position_create.thunks";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { selectionAdditionHierarchyPayload } from "./payload_model";
import { IAdditionPositionTranslation } from "models/addition_position/addition_position_translation";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

const initialState: AdditionPositionCreateState = {
    loadedData: {
        additionHierarchies: [],
    },
    actualData: {
        name: "",
        additionHierarchies: [],
        masspointSelection: {
            selectedList: {
                searchText: "",
                allItems: [],
                filteredItems: []
            },
            unSelectedList: {
                searchText: "",
                allItems: [],
                filteredItems: []
            }
        },
        additionPositionTranslations: [],
        selectedAdditionPositionTranslation: null
    },
    query: {
        fetchAllData: { status: "idle", canExecute: false },
        getMassPoints: { status: "idle", canExecute: true },
        getAdditionHierarchies: { status: "idle", canExecute: true },
        getAdditionPositionTranslations: { status: "idle", canExecute: true },
    },
    command: {
        saveAdditionPosition: { status: "idle", canExecute: false },
        cancelSaveAdditionPosition: { status: "idle", canExecute: false },
    }
}

export const additionPositionCreateSlice = createSlice({
    name: 'additionPosition/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.actualData = initialState.actualData;
        },
        cancelSaveAdditionPosition: (state) => {
            state.command.cancelSaveAdditionPosition.status = "success";
        },
        updateAdditionPositionName: (state, action: PayloadAction<string>) => {
            state.actualData.name = action.payload;
            state.command.saveAdditionPosition.canExecute = updateCanSave(state);
        },
        filterSelectedMasspoints: (state, action: PayloadAction<string>) => {
            state.actualData.masspointSelection.selectedList.searchText = action.payload;
            filterMasspoints(state.actualData.masspointSelection.selectedList);
        },
        filterUnselectedMasspoints: (state, action: PayloadAction<string>) => {
            state.actualData.masspointSelection.unSelectedList.searchText = action.payload;
            filterMasspoints(state.actualData.masspointSelection.unSelectedList);
        },
        selectMasspoints: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state, action.payload);
            state.command.saveAdditionPosition.canExecute = updateCanSave(state);
        },
        unselectMasspoints: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state, action.payload);
            state.command.saveAdditionPosition.canExecute = updateCanSave(state);
        },
        setSelectionAdditionHierarchy: (state, action: PayloadAction<selectionAdditionHierarchyPayload>) => {
            const payload = action.payload;
            const additionHierarchy = state.actualData.additionHierarchies.find(x => x.id === payload.id);
            additionHierarchy.isSelected = payload.isChecked;
            state.command.saveAdditionPosition.canExecute = updateCanSave(state);
        },
        selectTranslation: (state, action: PayloadAction<IAdditionPositionTranslation>) => {
            state.actualData.selectedAdditionPositionTranslation = action.payload;
            state.command.saveAdditionPosition.canExecute = updateCanSave(state);
        },
        handleSaveError: (state) => {
            state.command = {
                saveAdditionPosition: { status: "idle", canExecute: true },
                cancelSaveAdditionPosition: { status: "idle", canExecute: true }
            };
        },
    }, extraReducers: (builder) => {
        // fetch allData
        builder.addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = 'pending'
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success"

            //getMasspoints
        }).addCase(getMassPoints.pending, (state, action) => {
            state.query.getMassPoints.status = 'pending'
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error"
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success"
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteria = massPoints.filter(distinctMassPointCriteria).map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea,
                    displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                    id: mp.id
                }
            });

            state.actualData.masspointSelection.unSelectedList.allItems = massPointCriteria;
            filterMasspoints(state.actualData.masspointSelection.unSelectedList);

            //getAdditionHierarchies
        }).addCase(getAdditionHierarchies.pending, (state, action) => {
            state.query.getAdditionHierarchies.status = 'pending'
        }).addCase(getAdditionHierarchies.rejected, (state, action) => {
            state.query.getAdditionHierarchies.status = "error"
            state.query.getAdditionHierarchies.message = action.error.message;
        }).addCase(getAdditionHierarchies.fulfilled, (state, action) => {
            state.query.getAdditionHierarchies.status = "success"
            state.query.getAdditionHierarchies.canExecute = true;
            const additionHierarchies = action.payload.getData();
            state.loadedData.additionHierarchies = additionHierarchies;
            state.actualData.additionHierarchies = additionHierarchies.map(hierarchy => {
                return {
                    id: hierarchy.id,
                    name: hierarchy.name,
                    translationKeyForName: hierarchy.translationKeyForName,
                    isSelected: false
                }
            });

            //getAdditionPositionTranslations
        }).addCase(getAdditionPositionTranslations.pending, (state, action) => {
            state.query.getAdditionPositionTranslations.status = 'pending'
        }).addCase(getAdditionPositionTranslations.rejected, (state, action) => {
            state.query.getAdditionPositionTranslations.status = "error"
            state.query.getAdditionPositionTranslations.message = action.error.message;
        }).addCase(getAdditionPositionTranslations.fulfilled, (state, action) => {
            state.query.getAdditionPositionTranslations.status = "success"
            state.query.getAdditionPositionTranslations.canExecute = true;
            const translations = action.payload.getData();
            state.actualData.additionPositionTranslations = translations;

            //save
        }).addCase(saveAdditionPosition.pending, (state, action) => {
            state.command.saveAdditionPosition.status = "pending"
            state.command.saveAdditionPosition.canExecute = false;
        }).addCase(saveAdditionPosition.rejected, (state, action) => {
            state.command.saveAdditionPosition.status = "error"
            state.command.saveAdditionPosition.canExecute = true;
            state.command.saveAdditionPosition.message = action.error.message;
        }).addCase(saveAdditionPosition.fulfilled, (state, action) => {
            state.command.saveAdditionPosition.status = "success"
            state.command.saveAdditionPosition.message = undefined;
            state.command.saveAdditionPosition.canExecute = true;
        })
    }
});


export const {
    resetState,
    cancelSaveAdditionPosition,
    updateAdditionPositionName,
    filterSelectedMasspoints,
    filterUnselectedMasspoints,
    selectMasspoints,
    unselectMasspoints,
    setSelectionAdditionHierarchy,
    selectTranslation,
    handleSaveError,
} = additionPositionCreateSlice.actions

export default additionPositionCreateSlice.reducer