import { Grid } from '@mui/material';
import { AdditionPositionInformationComponent } from "./addition_position_basis_information";
import { IAdditionPositionActions } from "models/addition_position/addition_position_actions";
import { IAdditionPositionEditor, IAdditionPositionUpdateEditor } from "models/addition_position/addition_position_editor";
import { EditorButtonComponent } from 'shared/components/editors/editor_button/editor_buttons.component';

interface AdditionPositionEditorComponentProps {
    editor: IAdditionPositionEditor,
    editorActions: IAdditionPositionActions,
    canSave: boolean,
}

export const AdditionPositionEditorComponent: React.FC<AdditionPositionEditorComponentProps> = ({ editor, editorActions, canSave }) => {
        
    return (
        <Grid container
            direction="column"
            spacing={3}>
            <Grid item>
                <AdditionPositionInformationComponent editor={editor}
                    editorActions={editorActions} />
            </Grid>
            <EditorButtonComponent
                    canExecute={canSave}
                    save={editorActions.saveAdditionPosition}
                    cancelSave={editorActions.cancelSaveAdditionPosition}
                />            
        </Grid>
    );
}