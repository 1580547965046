
import { Grid, IconButton, TextField } from "@mui/material";
import { IAttributeMasspointConfiguration, IDefaultByAttributeEditor, IDefaultByAttributeEditorActions, IDefaultByAttributeLoadedData } from "../models/policy_mandatory_by_attribute.models";
import { DropdownComponent, FlexBox, PrimaryButtonComponent, Right } from "shared/shared";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { EditorMediumSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdditionPlaceHolderTypeEnum } from "models/additions/addition_placeholder.enum";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { MassPointCriteriaSide } from "models/masspoints/masspoint_criteria";
import { MasspointBodySideEnumValuesLookup } from "models/masspoints/enums/body_side.enum";
import { distinctMassPointCriteriaSide } from "check/policy/helper/policy.helper";
import { SelectionArticleTypeComponent } from "additions/addition/components/validities/addition_validity_item_articleType_selection.component";
import { SelectionQualityComponent } from "additions/addition/components/validities/addition_validity_item_quality_selection.component";

interface PolicyMandatoryByAttributeSubConfigComponentProps {
    editor: IDefaultByAttributeEditor;
    loadedData: IDefaultByAttributeLoadedData;
    actions: IDefaultByAttributeEditorActions;
}

export const PolicyMandatoryByAttributeSubConfigComponent = (props: PolicyMandatoryByAttributeSubConfigComponentProps) => {

    const calculateMainProductLines = () => {
        const editableConfig = props.editor.editableConfig;
        const mappedBaseData = props.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
        return mappedBaseData.availableMainProductLines;
    }

    const calculateBodyAreas = () => {
        const editableConfig = props.editor.editableConfig;
        const mappedBaseData = props.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);

        const selectedMainProductLine = mappedBaseData.availableMainProductLines
            .find(x => x.id === props.editor.editableSubConfig.mainProductLine.id);

        let alreadySelectedbodyAreas = [];
        if(doesExist(selectedMainProductLine)) {
            alreadySelectedbodyAreas = props.editor.editableConfig.subConfigs
                .filter((x, index) => x.mainProductLine.id === selectedMainProductLine.id && index !== props.editor.editableSubConfigIndex)
                .map(x => x.bodyArea.id) ;
        } 

        return selectedMainProductLine ? selectedMainProductLine.bodyAreas.filter(x => !alreadySelectedbodyAreas.some(y => y === x.id)) : [];
    }

    const mainProductLineSelected = isNullOrWhitespace(props.editor.editableSubConfig?.mainProductLine?.name);
    const bodyAreaSelected = isNullOrWhitespace(props.editor.editableSubConfig?.bodyArea?.name);

    const allArticleTypes = (): IAvailableArticleType[] => {
        let allItems = props.editor.editableSubConfig.articleTypes.selectedList.allItems;
        if (allItems.length === 0) {
            allItems = props.editor.editableSubConfig.articleTypes.unSelectedList.allItems;
        }

        return allItems;
    }

    const calculateArticleTypes = (): IAvailableArticleType[] => {
        let allItems = allArticleTypes();
        const masspointConfigurations = props.editor.editableSubConfig.attributeMasspointConfigurations;
        const alreadySelectedArticleTypeIds = masspointConfigurations.flatMap(x => x.articleType?.id);
        return allItems.filter(x => alreadySelectedArticleTypeIds.find(articleTypeId => articleTypeId === x.id) == undefined);
    }

    const isValid = (value: IAttributeMasspointConfiguration): boolean => {
        if (value.articleType == null) {
            return false;
        }

        if (value.masspointOne == null) {
            return false;
        }

        if (props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput) {
            if (value.masspointTwo == null) {
                return false;
            }
        }

        if (value.articleType == null) {
            return true;
        }
        const availableArticleTypes = allArticleTypes();
        const isValidArticleType = availableArticleTypes.map(x => x.id).includes(value.articleType.id)

        return isValidArticleType;
    }

    const buildMasspointDisplayText = (mp: MassPointCriteriaSide): string => {
        return `${mp.massPointName} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`;
    }

    const buildMasspointSideDisplayText = (mp: MassPointCriteriaSide): string => {
        return `${mp.massPointName} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name} ${MasspointBodySideEnumValuesLookup(mp.bodySide)}`;
    }

    const renderAttributeMasspointConfiguration = (value: IAttributeMasspointConfiguration, index: number) =>
        <Grid item key={index} sx={{ border: isValid(value) ? "transparent" : "2px solid red", margin : "0px 0px 10px 0px", padding : isValid(value) ? "0px" : "5px" }} >
            <DropdownComponent
                name="Artikelart"
                isReadonly={bodyAreaSelected}
                data={calculateArticleTypes()}
                isRequired={true}
                onSelect={(e) => props.actions.selectRangeArticleType({ articleType: e, index: index })}
                displayedProperties={["name"]}
                selectedValue={value.articleType}
            />
            {
                props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput &&
                <DropdownComponent
                    name="1. Maßpunkt"
                    isReadonly={bodyAreaSelected}
                    data={props.loadedData.masspoints.filter(x => x.bodyArea.id === props.editor.editableSubConfig.bodyArea.id).filter(distinctMassPointCriteriaSide)}
                    isRequired={true}
                    onSelect={(e) => props.actions.selectMasspointOne({ masspoint: e, index: index })}
                    renderEntry={buildMasspointDisplayText}
                    selectedValue={value.masspointOne}
                />
            }
            {
                props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput &&
                <>
                    <DropdownComponent
                        name="1. Maßpunkt"
                        isReadonly={bodyAreaSelected}
                        data={props.loadedData.masspoints.filter(x => x.bodyArea.id === props.editor.editableSubConfig.bodyArea.id)}
                        isRequired={true}
                        onSelect={(e) => props.actions.selectMasspointOne({ masspoint: e, index: index })}
                        renderEntry={buildMasspointSideDisplayText}
                        selectedValue={value.masspointOne}
                    />
                    <DropdownComponent
                        name="2. Maßpunkt"
                        isReadonly={bodyAreaSelected}
                        data={props.loadedData.masspoints.filter(x => x.bodyArea.id === props.editor.editableSubConfig.bodyArea.id)}
                        isRequired={true}
                        onSelect={(e) => props.actions.selectMasspointTwo({ masspoint: e, index: index })}
                        renderEntry={buildMasspointSideDisplayText}
                        selectedValue={value.masspointTwo}
                    />
                </>
            }
            <IconButton
                style={{ outline: "none" }}
                onClick={() => props.actions.removeMasspointConfiguration(index)}>
                <DeleteIcon />
            </IconButton>
        </Grid>

    const articleMassPointConfigDisabled = bodyAreaSelected 
        ||  ( 
                AdditionPlaceHolderTypeEnum.TwoRangeInput 
                && !doesExist(props.editor.editableSubConfig.defaultMasspointOne) 
                && !doesExist(props.editor.editableSubConfig.defaultMasspointTwo)
        )
        ||  (
                AdditionPlaceHolderTypeEnum.RangeInput 
                && !doesExist(props.editor.editableSubConfig.defaultMasspointOne)
        );

    return (
        <Grid spacing={3}
            direction="column"
            container
            marginBottom={"20px"}
            justifyContent="space-between">
            <Grid item md={12}>
                <EditorMediumSectionHeaderComponent style={{ marginTop: "0px", marginBottom: "25px" }}>Artikelabhängige Bedingung</EditorMediumSectionHeaderComponent>
            </Grid>
            <Grid item
                container
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Hauptproduktlinie"
                        data={calculateMainProductLines()}
                        isRequired={true}
                        onSelect={props.actions.updateMainProductLine}
                        displayedProperties={["name"]}
                        selectedValue={props.editor.editableSubConfig.mainProductLine}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="Körperregion"
                        data={mainProductLineSelected ? [] : calculateBodyAreas()}
                        isRequired={true}
                        isReadonly={mainProductLineSelected}
                        onSelect={props.actions.updateBodyArea}
                        displayedProperties={["name"]}
                        selectedValue={props.editor.editableSubConfig.bodyArea}
                    />
                </Grid>
            </Grid>
            <Grid container
                spacing={3}
                item
                md={12}>
                <SelectionArticleTypeComponent
                    onFilterSelected={props.actions.filterSelectedArticleType}
                    onFilterUnselected={props.actions.filterUnselectedArticleType}
                    select={props.actions.selectArticleTypes}
                    unselect={props.actions.unselectArticleTypes}
                    isReadOnly={bodyAreaSelected}
                    showSelectedAllIcon={true}
                    articleTypeSelection={props.editor.editableSubConfig.articleTypes} />
            </Grid>
            <Grid container
                spacing={3}
                item
                md={12}>
                <SelectionQualityComponent
                    onFilterSelected={props.actions.filterSelectedQuality}
                    onFilterUnselected={props.actions.filterUnselectedQuality}
                    select={props.actions.selectQualities}
                    unselect={props.actions.unselectQualities}
                    showSelectedAllIcon={true}
                    isReadOnly={bodyAreaSelected}
                    qualitySelection={props.editor.editableSubConfig.qualities}
                />
            </Grid>
            <Grid 
                spacing={3}
                direction="column"
                container
                item
                md={12}
                justifyContent="space-between">
                {
                    (props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput 
                    || props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput) &&
                    <Grid item>
                        <EditorMediumSectionHeaderComponent>Default</EditorMediumSectionHeaderComponent>
                    </Grid>
                }
                {
                    props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput &&
                    <Grid item>
                        <DropdownComponent
                            name="1. Maßpunkt"
                            isReadonly={bodyAreaSelected}
                            data={props.loadedData.masspoints.filter(x => x.bodyArea.id === props.editor.editableSubConfig.bodyArea.id).filter(distinctMassPointCriteriaSide)}
                            isRequired={true}
                            onSelect={(e) => props.actions.selectDefaultMasspointOne(e)}
                            renderEntry={buildMasspointDisplayText}
                            selectedValue={props.editor.editableSubConfig.defaultMasspointOne}
                        />
                        <IconButton
                            disabled={props.editor.editableSubConfig.attributeMasspointConfigurations.length > 0}
                            style={{ outline: "none" }}
                            onClick={() => props.actions.removeDefaultMasspointConfiguration()}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                }
                {
                    props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput &&
                    <Grid item>
                        <DropdownComponent
                            name="1. Maßpunkt"
                            isReadonly={bodyAreaSelected}
                            data={props.loadedData.masspoints.filter(x => x.bodyArea.id === props.editor.editableSubConfig.bodyArea.id)}
                            isRequired={true}
                            onSelect={(e) => props.actions.selectDefaultMasspointOne(e)}
                            renderEntry={buildMasspointSideDisplayText}
                            selectedValue={props.editor.editableSubConfig.defaultMasspointOne}
                        />
                        <DropdownComponent
                            name="2. Maßpunkt"
                            isReadonly={bodyAreaSelected}
                            data={props.loadedData.masspoints.filter(x => x.bodyArea.id === props.editor.editableSubConfig.bodyArea.id)}
                            isRequired={true}
                            onSelect={(e) => props.actions.selectDefaultMasspointTwo(e)}
                            renderEntry={buildMasspointSideDisplayText}
                            selectedValue={props.editor.editableSubConfig.defaultMasspointTwo}
                        />
                        <IconButton
                            disabled={props.editor.editableSubConfig.attributeMasspointConfigurations.length > 0}
                            style={{ outline: "none" }}
                            onClick={() => props.actions.removeDefaultMasspointConfiguration()}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                }
                <Grid item>
                {
                    props.editor.editableSubConfig.attributeMasspointConfigurations.map((value, index) => renderAttributeMasspointConfiguration(value, index))
                }
                </Grid>
                {
                    (props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput 
                        || props.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput) &&
                    <FlexBox>
                        <Right>
                            <PrimaryButtonComponent
                                disabled={articleMassPointConfigDisabled}
                                onClick={props.actions.addMasspointConfiguration}>
                                <AddIcon />{"Artikelabhängige Rangewert Konfiguration hinzufügen"}
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                }
            </Grid>
        </Grid>
    );
};