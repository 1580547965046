import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { initData } from './redux/addition.thunks';
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { getAdditionEditorActions, handleCommandAndQueryStatus } from "./addition.helper";
import { LoadingIndicatorComponent } from "shared/components/loading_indicator/loading_indicator.component";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { AdditionERPDataLoadComponent } from "./components/erp_data_load/addition_erp_data_load.component";
import { resetState } from "./redux/addition.slice";
import { AdditionDialogsComponent } from "./components/addition_dialogs.component";
import { AdditionEditorComponent } from "./components/addition_editor.component";

export const AdditionComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.addition);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { additionid } = useParams();

    useEffect(() => {
        dispatch(initData(additionid));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch, additionid]);

    if (state.query.initData.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    handleCommandAndQueryStatus(state, dispatch, navigate, enqueueSnackbar);

    const isLoading = state.command.updateAddition.status === "pending"
        || state.query.initData.status === "pending";

    if (isLoading) {
        return <LoadingIndicatorComponent />;
    }

    return (
        <>
            <AdditionDialogsComponent />

            {
                state.actualData.editorMode === EditorModeEnum.Create && state.actualData.showERPDataLoader &&
                <AdditionERPDataLoadComponent />
            }

            {
                !state.actualData.showERPDataLoader &&
                <AdditionEditorComponent additionInitial={state.loadedData.additionInitial}
                    additionEditor={state.actualData.additionEditor}
                    additionEditorActions={getAdditionEditorActions(state, dispatch)}
                />
            }
        </>
    )
};