import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import PolicyServiceAPI from 'services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import AdditionService from 'services/addition.service';
import AdditionAttributeCategoryServiceAPI from 'services/addition_attribute_category.service';
import BaseDataService from "services/base_data.service";
import MassPointService from "services/masspoint.service";
import { setMasspoint } from "../../common/helpers";

export const getAvailableAttributeCategoriesAndAttributes = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/getAvailableAttributeCategoriesAndAttributes',
    async (_) => {
        const response = await AdditionAttributeCategoryServiceAPI.getAvailableAttributeCategoriesAndAttributesByPolicy(PolicyTypeEnum.DefaultByAttribute)
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getAdditions = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/getAdditions',
    async (_) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.DefaultByAttribute, undefined);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getMappedBaseDataByAddition = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/getMappedBaseDataByAddition',
    async (additionId: number) => {
        const response = await BaseDataService.getMappedBaseDataByAddition(additionId);
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_by_attribute_create.data;
        const response = await PolicyServiceAPI.createMandatoryByAttribute({
            name: data.editor.policy.name,
            ignoreOnQuotation: data.editor.policy.ignoreOnQuotation,
            attributeId: data.editor.selectedAttribute.id,
            configs: data.editor.configs.map(conf => ({
                additionId: conf.addition.id,
                subConfigs: conf.subConfigs.map(subConfig => ({
                    mainProductLineId: subConfig.mainProductLine.id,
                    bodyAreaId: subConfig.bodyArea.id,
                    articleTypeIds: subConfig.articleTypes.selectedList.allItems.map(x => x.id),
                    qualityIds: subConfig.qualities.selectedList.allItems.map(x => x.id),
                    defaultMasspointOne: setMasspoint(subConfig.defaultMasspointOne),
                    defaultMasspointTwo: setMasspoint(subConfig.defaultMasspointTwo),
                    attributeMasspointConfigurations: subConfig.attributeMasspointConfigurations.map(x => ({
                        articleTypeId: x.articleType.id,
                        masspointOne: setMasspoint(x.masspointOne),
                        masspointTwo: setMasspoint(x.masspointTwo),
                    }))
                }))
            }))
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/create_policy_mandatory_by_attribute/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(getAvailableAttributeCategoriesAndAttributes()),
            dispatch(getAdditions()),
            dispatch(getMassPoints())
        ]);
    }
)