
import { Grid } from "@mui/material";
import { EditorConfigurationComponent } from "shared/components/editors/list_editor/editor_configuration.component";
import { ListEditorComponent } from "shared/components/editors/list_editor/list_editor.component";
import { DropdownComponent } from "shared/shared";
import { IDefaultByAttributeEditor, IDefaultByAttributeEditorActions, IDefaultByAttributeLoadedData, IDefaultByAttributeSubConfigViewModel } from "../models/policy_mandatory_by_attribute.models";
import { PolicyMandatoryByAttributeSubConfigComponent } from "./policy_mandatory_by_attribute_sub_config.component";
import { ListEditorMode } from "shared/components/editors/list_editor/enums/EditorMode";
import { EditorSummaryDefinition } from "shared/components/editors/list_editor/models/EditorSummaryDefinition";
import { doesExist } from "services/validation.service";
import { LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { EditorMediumSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { isValidSubConfig, isValidSubConfigByIndex } from "../common/helpers";

interface PolicyMandatoryByAttributeConfigComponentProps {
    editor: IDefaultByAttributeEditor;
    actions: IDefaultByAttributeEditorActions;
    loadedData: IDefaultByAttributeLoadedData;
}

export const PolicyMandatoryByAttributeConfigComponent = (props: PolicyMandatoryByAttributeConfigComponentProps) => {

    const editableConfig = props.editor.editableConfig;
    const mappedBaseData = props.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
    const noMappedBaseDataForAddition = doesExist(mappedBaseData) && mappedBaseData.availableMainProductLines.length === 0;
    const mappedBaseDataExistsForAddition = doesExist(mappedBaseData) && mappedBaseData.availableMainProductLines.length > 0;
    const isRangePlaceholder = Boolean(props.editor.editableConfig.addition?.placeholder?.rangePlaceholder);
    const isMinMaxPlaceholder = Boolean(props.editor.editableConfig.addition?.placeholder?.twoRangePlaceholder);

    const renderContent = () => {
        return (
            <PolicyMandatoryByAttributeSubConfigComponent editor={props.editor} actions={props.actions} loadedData={props.loadedData} />
        );
    };

    const renderSubConfig = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isValidSubConfig(props.editor.editableSubConfig, mappedBaseData, isMinMaxPlaceholder)}
            onTakeConfiguration={() => props.actions.onTakeSubConfig()}
            onCancelConfiguration={props.actions.onCancelSubConfig}
        />
    }

    const editorSummaryDefinitions: EditorSummaryDefinition[] = [{
        heading: "Hauptproduktlinie",
        flex: 1,
        displayValue: (value: IDefaultByAttributeSubConfigViewModel) => value.mainProductLine.erpId + " " + value.mainProductLine.name
    },
    {
        heading: "Körperregion",
        flex: 1,
        displayValue: (value: IDefaultByAttributeSubConfigViewModel) => value.bodyArea.erpId + " " + value.bodyArea.name
    }];


    const getEditorMode = () => {
        if (doesExist(props.editor.editableSubConfig)) {
            return props.editor.editableSubConfigIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        }
        else {
            return ListEditorMode.None;
        }
    }

    const _isValidSubConfigByIndex = (index: number) => {
        return isValidSubConfigByIndex(index, props.editor.editableConfig.subConfigs, mappedBaseData, isMinMaxPlaceholder)
    }

    return (
        <Grid
            item container md={12}
            direction="column"
            spacing={3}>
            <Grid item md={12}>
                <Grid item md={12}>
                    <EditorMediumSectionHeaderComponent style={{ marginTop: "0px", marginBottom: "25px" }}>Zusatz für automatische Auswahl</EditorMediumSectionHeaderComponent>
                </Grid>
                <Grid container item md={12} spacing={3}
                    direction="row">
                    <Grid item>
                        <DropdownComponent name="Zusatz"
                            data={props.editor.editableConfig.availableAdditions}
                            minWidth="350px"
                            isRequired={true}
                            isReadonly={!!props.editor.editableSubConfig}
                            onSelect={(ad) => props.actions.onSelectAddition(ad)}
                            displayedProperties={["option", "description"]}
                            selectedValue={props.editor.editableConfig.addition}
                        />
                    </Grid>
                    <Grid item>
                        {isRangePlaceholder && <div>
                            <div style={{ fontWeight: 600 }}>Konfigurierte Rangewerte:</div>
                            <div>{`Range : ${props.editor.editableConfig.addition.placeholder.rangePlaceholder.valueStart} cm - ${props.editor.editableConfig.addition.placeholder.rangePlaceholder.valueEnd} cm`}</div>
                        </div>}
                        {isMinMaxPlaceholder && <div>
                            <div style={{ fontWeight: 600 }}>Konfigurierte Rangewerte:</div>
                            <div>{`Range 1: ${props.editor.editableConfig.addition.placeholder.twoRangePlaceholder.firstMinimalValue} cm - ${props.editor.editableConfig.addition.placeholder.twoRangePlaceholder.firstMaximalValue} cm`}</div>
                            <div>{`Range 2: ${props.editor.editableConfig.addition.placeholder.twoRangePlaceholder.secondMinimalValue} cm - ${props.editor.editableConfig.addition.placeholder.twoRangePlaceholder.secondMaximalValue} cm`}</div>
                        </div>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12}>
                <Grid item md={12}>
                    <EditorMediumSectionHeaderComponent>Artikelabhängige Bedingung</EditorMediumSectionHeaderComponent>
                </Grid>
                <Grid item md={12} paddingTop={"10px"}>
                    Es ist möglich die  Bedingung für eine automatische Auswahl des Zusatzes weiter einzuschränken.
                </Grid>
                {
                    mappedBaseDataExistsForAddition &&
                    <LoadingOverlayContainer>
                        <ListEditorComponent
                            addButtonText="Neue Konfiguration hinzufügen"
                            editorSummaryDefinitions={editorSummaryDefinitions}
                            items={props.editor.editableConfig.subConfigs}
                            isNested={true}
                            isValid={_isValidSubConfigByIndex}
                            onEditConfiguration={props.actions.onEditSubConfig}
                            onDeleteConfiguration={props.actions.onDeleteSubConfig}
                            onNewConfiguration={props.actions.onNewSubConfig}
                            renderConfiguration={props.editor.editableSubConfig != null ? renderSubConfig : null}
                            editorMode={getEditorMode()}
                            editableConfigurationIndex={props.editor.editableSubConfigIndex}
                        />
                    </LoadingOverlayContainer>
                }
                {
                    noMappedBaseDataForAddition &&
                    <Grid item md={12} paddingTop={"10px"}>
                        Für diesen Zusatz sind keine weiteren Einschränkungen möglich.
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};