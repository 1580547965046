import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateCanSave } from './policy_mandatory_by_attribute_create.reducer';
import { cancelSave, getAdditions, getAvailableAttributeCategoriesAndAttributes, getMappedBaseDataByAddition, getMassPoints, initData, savePolicy } from './policy_mandatory_by_attribute_create.thunks';
import { PolicyMandatoryByAttributeCreateState as PolicyMandatoryByAttributeCreateState } from './policy_mandatory_by_attribute_create.model';
import { IAvailableAdditionAttributeCategory } from 'models/addition_attribute_categories/available_addition_attribute_category';
import { IAvailableAdditionAttribute } from 'models/addition_attribute_categories/available_addition_attribute';
import { IAdditionPolicyInformation } from 'models/additions/addition_policy_information';
import { calculateArticleTypeSelection, calculateDependentFields, calculateQualitiesSelection, createEditConfig, createEditSubConfig, createMasspointItemList, createNewConfig, createNewSubConfig, isValidConfig, moveArticleTypeFromSelectedToUnselected, moveArticleTypeFromUnselectedToSelected, moveQualityFromSelectedToUnselected, moveQualityFromUnselectedToSelected } from '../../common/helpers';
import { filterArticleTypes, filterQualities } from 'additions/common/helpers/filters';
import IAvailableBodyArea from 'models/available_basedata/available_body_area';
import IAvailableProductLine from 'models/available_basedata/available_product_line';
import IAvailableQuality from 'models/available_basedata/available_quality';
import IAvailableArticleType from 'models/available_basedata/available_article_type';
import { IMassPointCriteriaItem } from 'check/policy/components/policy_editor.model';
import { SelectArticleTypePayload, SelectMasspointPayload } from '../../models/policy_mandatory_by_attribute.models';
import { MassPointCriteria, MassPointCriteriaSide } from 'models/masspoints/masspoint_criteria';

const initialState: PolicyMandatoryByAttributeCreateState = {
    data: {
        editor: {
            isValidPolicyDefinition: false,
            selectedCategory: null,
            selectedAttribute: null,
            configs: [],
            editableConfig: null,
            editableConfigIndex: -1,
            editableSubConfig: null,
            editableSubConfigIndex: -1,
            policy: {
                name: "",
                ignoreOnQuotation: false
            }
        },
    },
    loadedData: {
        additions: [],
        masspoints: [],
        attributeCategories: [],
        mappedBaseDatasByAddition: []
    },
    command: {
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        initData: { status: "idle", canExecute: true },
        fetchAvailableAttributeCategoriesAndAttributes: { status: "idle", canExecute: true },
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },
        fetchMappedBaseData: { status: "idle", canExecute: true },
    }
}

export const createPolicyMandatoryByAttribute = createSlice({
    name: 'create_policy_mandatory_by_attribute',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.editor.policy.name = action.payload;
            updateCanSave(state);
        },
        selectCategory: (state, action: PayloadAction<IAvailableAdditionAttributeCategory>) => {
            state.data.editor.selectedCategory = action.payload;
            state.data.editor.selectedAttribute = null;
            updateCanSave(state);
        },
        selectAttribute: (state, action: PayloadAction<IAvailableAdditionAttribute>) => {
            state.data.editor.selectedAttribute = action.payload;
            updateCanSave(state);
        },
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.editor.editableConfig.addition = action.payload;
            state.data.editor.editableConfig.isValid = isValidConfig(state.data.editor.editableConfig);
        },
        newConfig: (state) => {
            state.data.editor.editableConfig = createNewConfig(state.data.editor, state.loadedData);
            state.data.editor.editableConfigIndex = -1;
            updateCanSave(state);
        },
        editConfig: (state, action: PayloadAction<number>) => {
            state.data.editor.editableConfigIndex = action.payload;
            state.data.editor.editableConfig = createEditConfig(state.data.editor.configs[action.payload], state.data.editor.configs, state.loadedData);
            updateCanSave(state);
        },
        takeConfig: (state) => {
            if (state.data.editor.editableConfigIndex === -1) {
                state.data.editor.configs = [...state.data.editor.configs, state.data.editor.editableConfig];
            }
            else {
                state.data.editor.configs = state.data.editor.configs.map((con, index) =>
                    index === state.data.editor.editableConfigIndex ? state.data.editor.editableConfig : con
                );
            }
            state.data.editor.editableConfig = null;
            state.data.editor.editableConfigIndex = -1;
            updateCanSave(state);
        },
        deleteConfig: (state, action: PayloadAction<number>) => {
            state.data.editor.configs = state.data.editor.configs.filter((x, index) => action.payload !== index);
            updateCanSave(state);
        },
        cancelConfig: (state) => {
            state.data.editor.editableConfig = null;
            state.data.editor.editableConfigIndex = -1;
            updateCanSave(state);
        },
        newSubConfig: (state) => {
            state.data.editor.editableSubConfig = createNewSubConfig();
            state.data.editor.editableSubConfigIndex = -1;
            updateCanSave(state);
        },
        editSubConfig: (state, action: PayloadAction<number>) => {
            state.data.editor.editableSubConfigIndex = action.payload;
            state.data.editor.editableSubConfig = createEditSubConfig(state.data.editor.editableConfig.subConfigs[action.payload]);

            const editableConfig = state.data.editor.editableConfig;
            const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.articleTypes = calculateArticleTypeSelection(editableSubConfig, mappedBaseData);
            editableSubConfig.qualities = calculateQualitiesSelection(editableSubConfig, mappedBaseData);
            updateCanSave(state);
        },
        takeSubConfig: (state) => {
            if (state.data.editor.editableSubConfigIndex === -1) {
                state.data.editor.editableConfig.subConfigs = [...state.data.editor.editableConfig.subConfigs, state.data.editor.editableSubConfig];
            }
            else {
                state.data.editor.editableConfig.subConfigs = state.data.editor.editableConfig.subConfigs.map((con, index) =>
                    index === state.data.editor.editableSubConfigIndex ? state.data.editor.editableSubConfig : con
                );
            }
            state.data.editor.editableSubConfig = null;
            state.data.editor.editableSubConfigIndex = -1;
            updateCanSave(state);
        },
        deleteSubConfig: (state, action: PayloadAction<number>) => {
            state.data.editor.editableConfig.subConfigs = state.data.editor.editableConfig.subConfigs.filter((x, index) => action.payload !== index);
            updateCanSave(state);
        },
        cancelSubConfig: (state) => {
            state.data.editor.editableSubConfig = null;
            state.data.editor.editableSubConfigIndex = -1;
            updateCanSave(state);
        },
        updateBodyArea: (state, action: PayloadAction<IAvailableBodyArea>) => {
            const editableConfig = state.data.editor.editableConfig;
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.bodyArea = action.payload;
            const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
            editableSubConfig.articleTypes = calculateArticleTypeSelection(editableSubConfig, mappedBaseData);
            editableSubConfig.qualities = calculateQualitiesSelection(editableSubConfig, mappedBaseData);
            updateCanSave(state)
        },
        updateMainProductLine: (state, action: PayloadAction<IAvailableProductLine>) => {
            const editableConfig = state.data.editor.editableConfig;
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.mainProductLine = action.payload;
            const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
            calculateDependentFields(editableSubConfig, mappedBaseData);
            updateCanSave(state);
        },
        filterSelectedArticleType: (state, action: PayloadAction<string>) => {
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.articleTypes.selectedList.searchText = action.payload;
            filterArticleTypes(editableSubConfig.articleTypes.selectedList);
        },
        filterUnselectedArticleType: (state, action: PayloadAction<string>) => {
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.articleTypes.unSelectedList.searchText = action.payload;
            filterArticleTypes(editableSubConfig.articleTypes.unSelectedList);
        },
        filterSelectedQuality: (state, action: PayloadAction<string>) => {
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.qualities.selectedList.searchText = action.payload;
            filterQualities(editableSubConfig.qualities.selectedList);
        },
        filterUnselectedQuality: (state, action: PayloadAction<string>) => {
            const editableSubConfig = state.data.editor.editableSubConfig;
            editableSubConfig.qualities.unSelectedList.searchText = action.payload;
            filterQualities(editableSubConfig.qualities.unSelectedList);
        },
        selectArticleTypes: (state, action: PayloadAction<IAvailableArticleType[]>) => {
            const editableConfig = state.data.editor.editableConfig;
            const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
            moveArticleTypeFromUnselectedToSelected(state.data.editor.editableSubConfig, mappedBaseData, action.payload);
        },
        unselectArticleTypes: (state, action: PayloadAction<IAvailableArticleType[]>) => {
            const editableConfig = state.data.editor.editableConfig;
            const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
            moveArticleTypeFromSelectedToUnselected(state.data.editor.editableSubConfig, mappedBaseData, action.payload);
        },
        selectQualities: (state, action: PayloadAction<IAvailableQuality[]>) => {
            moveQualityFromUnselectedToSelected(state.data.editor.editableSubConfig, action.payload);
        },
        unselectQualities: (state, action: PayloadAction<IAvailableQuality[]>) => {
            moveQualityFromSelectedToUnselected(state.data.editor.editableSubConfig, action.payload);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.editor.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        },
        addMasspointConfiguration: (state) => {
            state.data.editor.editableSubConfig.attributeMasspointConfigurations.push({
                masspointOne: null,
                masspointTwo: null,
                articleType: null,
            });
        },
        removeMasspointConfiguration: (state, action: PayloadAction<number>) => {
            state.data.editor.editableSubConfig.attributeMasspointConfigurations.splice(action.payload, 1);
        },
        removeDefaultMasspointConfiguration: (state) => {
            state.data.editor.editableSubConfig.defaultMasspointOne = null;
            state.data.editor.editableSubConfig.defaultMasspointTwo = null;
        },                
        selectMasspointOne: (state, action: PayloadAction<SelectMasspointPayload>) => {
            let masspointConfig = state.data.editor.editableSubConfig.attributeMasspointConfigurations[action.payload.index];
            masspointConfig.masspointOne = action.payload.masspoint;
        },
        selectMasspointTwo: (state, action: PayloadAction<SelectMasspointPayload>) => {
            let masspointConfig = state.data.editor.editableSubConfig.attributeMasspointConfigurations[action.payload.index];
            masspointConfig.masspointTwo = action.payload.masspoint;
        },
        selectDefaultMasspointOne: (state, action: PayloadAction<MassPointCriteriaSide>) => {
            state.data.editor.editableSubConfig.defaultMasspointOne = action.payload;
        },
        selectDefaultMasspointTwo: (state, action: PayloadAction<MassPointCriteriaSide>) => {
            state.data.editor.editableSubConfig.defaultMasspointTwo = action.payload;
        },
        selectRangeArticleType: (state, action: PayloadAction<SelectArticleTypePayload>) => {
            let masspointConfig = state.data.editor.editableSubConfig.attributeMasspointConfigurations[action.payload.index];
            masspointConfig.articleType = action.payload.articleType;
        },
    }, extraReducers: (builder) => {
        //fetchAvailableAttributeCategoriesAndAttributes
        builder.addCase(getAvailableAttributeCategoriesAndAttributes.pending, (state) => {
            state.query.fetchAvailableAttributeCategoriesAndAttributes.status = "pending"
            state.query.fetchAvailableAttributeCategoriesAndAttributes.canExecute = false;
        }).addCase(getAvailableAttributeCategoriesAndAttributes.rejected, (state, action) => {
            state.query.fetchAvailableAttributeCategoriesAndAttributes.status = "error"
            state.query.fetchAvailableAttributeCategoriesAndAttributes.message = action.error.message;
            state.query.fetchAvailableAttributeCategoriesAndAttributes.canExecute = true;
        }).addCase(getAvailableAttributeCategoriesAndAttributes.fulfilled, (state, action) => {
            state.query.fetchAvailableAttributeCategoriesAndAttributes.status = "success"
            state.query.fetchAvailableAttributeCategoriesAndAttributes.canExecute = true;
            state.loadedData.attributeCategories = action.payload.getData();

            // fetchAdditions
        }).addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData();

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            state.loadedData.masspoints = createMasspointItemList(action.payload.getData());

            // fetchMappedBaseData
        }).addCase(getMappedBaseDataByAddition.pending, (state) => {
            state.query.fetchMappedBaseData.status = "pending"
            state.query.fetchMappedBaseData.canExecute = false;
        }).addCase(getMappedBaseDataByAddition.rejected, (state, action) => {
            state.query.fetchMappedBaseData.status = "error"
            state.query.fetchMappedBaseData.message = action.error.message;
            state.query.fetchMappedBaseData.canExecute = true;
        }).addCase(getMappedBaseDataByAddition.fulfilled, (state, action) => {
            state.query.fetchMappedBaseData.status = "success"
            state.query.fetchMappedBaseData.canExecute = true;
            state.loadedData.mappedBaseDatasByAddition.push(action.payload.getData());

            // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            state.data.editor.editableConfig = createNewConfig(state.data.editor, state.loadedData);
            state.data.editor.editableConfigIndex = -1;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectCategory,
    selectAttribute,
    selectAddition,
    newConfig,
    takeConfig,
    editConfig,
    deleteConfig,
    cancelConfig,
    toggleIgnoreOnQuotation,
    completedSave,
    editSubConfig,
    deleteSubConfig,
    newSubConfig,
    takeSubConfig,
    cancelSubConfig,
    updateMainProductLine,
    updateBodyArea,
    filterSelectedArticleType,
    filterUnselectedArticleType,
    selectArticleTypes,
    unselectArticleTypes,
    filterSelectedQuality,
    filterUnselectedQuality,
    selectQualities,
    unselectQualities,
    addMasspointConfiguration,
    removeMasspointConfiguration,
    removeDefaultMasspointConfiguration,
    selectMasspointOne,
    selectMasspointTwo,
    selectDefaultMasspointOne,
    selectDefaultMasspointTwo,
    selectRangeArticleType,
} = createPolicyMandatoryByAttribute.actions

export default createPolicyMandatoryByAttribute.reducer