import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingIndicatorComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate, useParams } from "react-router-dom";
import { cancelSave, getMappedBaseDataByAddition, initData, updatePolicy } from "./redux/policy_mandatory_by_attribute_update.thunks";
import {
    changeName,
    completedSave,
    resetState,
    selectAttribute,
    selectCategory,
    selectAddition,
    cancelConfig,
    cancelSubConfig,
    deleteConfig,
    deleteSubConfig,
    editConfig,
    editSubConfig,
    filterSelectedArticleType,
    filterSelectedQuality,
    filterUnselectedArticleType,
    filterUnselectedQuality,
    newConfig,
    newSubConfig,
    takeConfig,
    takeSubConfig,
    updateBodyArea,
    updateMainProductLine,
    toggleIgnoreOnQuotation,
    addMasspointConfiguration,
    removeMasspointConfiguration,
    selectMasspointOne,
    selectMasspointTwo,
    selectDefaultMasspointOne,
    selectDefaultMasspointTwo,
    selectRangeArticleType,
    selectQualities,
    unselectQualities,
    selectArticleTypes,
    unselectArticleTypes,
    removeDefaultMasspointConfiguration
} from "./redux/policy_mandatory_by_attribute_update.slice";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import i18next from "i18next";
import { PolicyMandatoryByAttributeEditorComponent } from "../components/policy_mandatory_by_attribute_editor.component";
import { IDefaultByAttributeEditorActions, SelectArticleTypePayload, SelectMasspointPayload } from "../models/policy_mandatory_by_attribute.models";
import { MassPointCriteriaSide } from "models/masspoints/masspoint_criteria";

export const PolicyMandatoryByAttributeUpdateComponent = () => {
    const state = useAppSelector((state) => state.policy_mandatory_by_attribute_update);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (state.data.editor.editableConfig?.addition) {
            const additionId = state.data.editor.editableConfig.addition.id;
            const mappedDataAlreadyLoaded = state.loadedData.mappedBaseDatasByAddition.some(x => x.additionId === additionId);
            if (!mappedDataAlreadyLoaded) {
                dispatch(getMappedBaseDataByAddition([additionId]));
            }
        }
    }, [state.data.editor.editableConfig?.addition]);

    useEffect(() => {
        dispatch(getMappedBaseDataByAddition(state.loadedData.policy?.configs.map(x => x.additionId)));
    }, [state.loadedData.policy]);

    useEffect(() => {
        dispatch(initData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.updatePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.updatePolicy.status === "error") {
        enqueueSnackbar(state.command.updatePolicy.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.updatePolicy.status === "warning") {
        enqueueSnackbar(state.command.updatePolicy.message, { variant: "warning" });
        dispatch(completedSave());
    }



    const editorActions: IDefaultByAttributeEditorActions = {
        onSelectCategory: (cat) => dispatch(selectCategory(cat)),
        onSelectAttribute: (att) => dispatch(selectAttribute(att)),
        onSelectAddition: (addition) => dispatch(selectAddition(addition)),
        onNewConfig: () => dispatch(newConfig()),
        onEditConfig: (index) => dispatch(editConfig(index)),
        onDeleteConfig: (index) => dispatch(deleteConfig(index)),
        onTakeConfig: () => dispatch(takeConfig()),
        onCancelConfig: () => dispatch(cancelConfig()),
        onEditSubConfig: (index) => dispatch(editSubConfig(index)),
        onDeleteSubConfig: (index) => dispatch(deleteSubConfig(index)),
        onNewSubConfig: () => dispatch(newSubConfig()),
        onTakeSubConfig: () => dispatch(takeSubConfig()),
        onCancelSubConfig: () => dispatch(cancelSubConfig()),
        updateMainProductLine: (newValue) => dispatch(updateMainProductLine(newValue)),
        updateBodyArea: (newValue) => dispatch(updateBodyArea(newValue)),
        filterSelectedArticleType: (searchText) => dispatch(filterSelectedArticleType(searchText)),
        filterUnselectedArticleType: (searchText) => dispatch(filterUnselectedArticleType(searchText)),
        selectArticleTypes: (selection) => dispatch(selectArticleTypes(selection)),
        unselectArticleTypes: (selection) => dispatch(unselectArticleTypes(selection)),
        filterSelectedQuality: (searchText) => dispatch(filterSelectedQuality(searchText)),
        filterUnselectedQuality: (searchText) => dispatch(filterUnselectedQuality(searchText)),
        selectQualities: (selection) => dispatch(selectQualities(selection)),
        unselectQualities: (selection) => dispatch(unselectQualities(selection)),
        addMasspointConfiguration: () => dispatch(addMasspointConfiguration()),
        removeMasspointConfiguration: (index: number) => dispatch(removeMasspointConfiguration(index)),
        removeDefaultMasspointConfiguration: () => dispatch(removeDefaultMasspointConfiguration()),
        selectMasspointOne: (value: SelectMasspointPayload) => dispatch(selectMasspointOne(value)),
        selectMasspointTwo: (value: SelectMasspointPayload) => dispatch(selectMasspointTwo(value)),
        selectDefaultMasspointOne: (value: MassPointCriteriaSide) => dispatch(selectDefaultMasspointOne(value)),
        selectDefaultMasspointTwo: (value: MassPointCriteriaSide) => dispatch(selectDefaultMasspointTwo(value)),
        selectRangeArticleType: (value: SelectArticleTypePayload) => dispatch(selectRangeArticleType(value)),
    }

    const isSaving = state.command.updatePolicy.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {(isSaving || state.query.fetchMappedBaseData.status === "pending") ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Automatischen Zusatz für Attribute editieren</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.autocheck_attribute.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.editor.policy.name}
                        ignoreOnQuotation={state.data.editor.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    <PolicyMandatoryByAttributeEditorComponent
                        editor={state.data.editor}
                        actions={editorActions}
                        loadedData={state.loadedData}
                    />
                    <EditorButtonComponent
                        canExecute={state.command.updatePolicy.canExecute}
                        save={() => dispatch(updatePolicy())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};