import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import { cancelSaveAdditionPosition, filterSelectedMasspoints, filterUnselectedMasspoints, handleSaveError, resetState, selectMasspoints, selectTranslation, setSelectionAdditionHierarchy, unselectMasspoints, updateAdditionPositionName } from "./redux/addition_position_edit.slice";
import { fetchAllData, updateAdditionPosition } from "./redux/addition_position_edit.thunks";
import { useEffect } from 'react';
import { LoadingIndicatorComponent } from 'shared/components/loading_indicator/loading_indicator.component';
import { AdditionPositionEditorComponent } from "../components/addition_position_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from 'shared/components/loading_overlay/loading_overlay.component';
import { IAdditionPositionActions } from "models/addition_position/addition_position_actions";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from 'shared/contexts/dialog_context';

export const AdditionPositionEditComponent = () => {
    const { id } = useParams();
    const state = useAppSelector((state) => state.addition_position_edit);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dialog = useDialog();

    useEffect(() => {
        dispatch(fetchAllData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch, id]);

    const editorActions: IAdditionPositionActions = {
        resetState: () => dispatch(resetState()),
        filterSelectedMasspoints: (searchText) => dispatch(filterSelectedMasspoints(searchText)),
        filterUnselectedMasspoints: (searchText) => dispatch(filterUnselectedMasspoints(searchText)),
        selectMasspoints: (ids) => dispatch(selectMasspoints(ids)),
        unselectMasspoints: (ids) => dispatch(unselectMasspoints(ids)),
        updateAdditionPositionName: (value) => dispatch(updateAdditionPositionName(value)),
        cancelSaveAdditionPosition: () => dispatch(cancelSaveAdditionPosition()),
        saveAdditionPosition: () => dispatch(updateAdditionPosition(false)),
        setSelectionAdditionHierarchy: (payload) => dispatch(setSelectionAdditionHierarchy(payload)),
        selectTranslation: (payload) => dispatch(selectTranslation(payload)),
    };

    if (state.query.fetchAllData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelUpdateAdditionPosition.status === "success") {
        navigate("/additions/additionposition");
        dispatch(resetState());
    }

    if (state.command.updateAdditionPosition.status === "success") {
        enqueueSnackbar("Zusatzposition gespeichert", { variant: "success" });
        navigate("/additions/additionposition");
        dispatch(resetState());
    }

    if (state.command.updateAdditionPosition.status === "error") {
        if (state.error.showErrorDialog) {
            if(state.error.errorCode === "422") {
                dialog.show(
                    "Zusatzposition konnte nicht gespeichert werden",
                    <div>{state.command.updateAdditionPosition.message}</div>,
                    [{
                        title: "Ok",
                        handleOption: () => { }
                    }]);
            } else if(state.error.errorCode === "400"){
                dialog.show(
                    "Zusatzposition konnte nicht gespeichert werden",
                    <div>{state.command.updateAdditionPosition.message} Ist die Änderung mit der Produktion abgesprochen?</div>,
                    [{
                        title: "Nein",
                        handleOption: () => { }
                    },{
                        title: "Ja",
                        handleOption: () => { dispatch(updateAdditionPosition(true))}
                    }]);
            }
        }
        else {
            enqueueSnackbar(state.command.updateAdditionPosition.message, { variant: "error" });
        }
        dispatch(handleSaveError());
    }

    if (state.command.updateAdditionPosition.status === "warning") {
        enqueueSnackbar(state.command.updateAdditionPosition.message, { variant: "warning" });
        dispatch(handleSaveError());
    }

    const isSaving = state.command.updateAdditionPosition.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <AdditionPositionEditorComponent
                    editor={state.actualData}
                    editorActions={editorActions}
                    canSave={state.command.updateAdditionPosition.canExecute} />
            </LoadingOverlayContainer>
        </>
    );
};