import { DropdownComponent, TextBoxComponent } from "shared/shared";
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { IAdditionPositionActions } from "models/addition_position/addition_position_actions";
import { IAdditionPositionEditor } from "models/addition_position/addition_position_editor";
import { getConfig } from "services/config.service";
import { CalingaLinkComponent } from "shared/components/editors/calinga_link/calinga_link.component";

interface AdditionPositionInformationComponentProps {
    editor: IAdditionPositionEditor,
    editorActions: IAdditionPositionActions,
}

export const AdditionPositionInformationComponent: React.FC<AdditionPositionInformationComponentProps> = ({ editor, editorActions }) => {
    const generateCalingaLink = () => {
        const c = getConfig().calinga;
        return `${c.baseUrl}/translations/${c.organization}/${c.team}/${c.project.additionPositions}/${c.referenceLanguage}`
    }

    const renderAdditionHierarchies = () => {
        return (
            <>
                <Typography>
                    <strong>
                        Zusatzhierarchie (mindestens eine)*
                    </strong>
                </Typography>
                {
                    editor.additionHierarchies.map(x => {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={x.isSelected}
                                        onChange={(event, checked) => editorActions.setSelectionAdditionHierarchy({ id: x.id, isChecked: checked })}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={x.name}
                            />
                        )
                    })
                }
            </>
        );
    }

    const selectedTranslation = editor.selectedAdditionPositionTranslation != null;

    return (
        <Grid container
            direction="column"
            spacing={3}>
            <Grid item md={6}>
                <TextBoxComponent name="Name der Zusatzposition"
                    value={editor.name}
                    onChange={(value) => editorActions.updateAdditionPositionName(value)}
                    placeholder={"z.B. Wade"}
                    isRequired={true}
                    isAutoFocus={true}
                    maxLength={20}
                    width={"100%"}
                />
            </Grid>
            <Grid item container direction={'column'} md={9} spacing={1}>
                <Grid item container direction={'row'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                    <Grid item>
                        <DropdownComponent
                            name="Übersetzungskey"
                            data={editor.additionPositionTranslations}
                            isLoading={false}
                            isRequired={true}
                            onSelect={(translation) => editorActions.selectTranslation(translation)}
                            displayedProperties={["key"]}
                            selectedValue={editor.selectedAdditionPositionTranslation}
                        />
                    </Grid>
                    {selectedTranslation && <Grid item>
                        <Typography textAlign={'end'}>
                            {editor.selectedAdditionPositionTranslation?.value}
                        </Typography>
                    </Grid>}
                </Grid>
                <CalingaLinkComponent projectName={getConfig().calinga.project.additionPositions} />
            </Grid>

            <Grid item md={12}>
                {renderAdditionHierarchies()}
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    title="Maßpunkte verknüpfen"
                    entityName="Maßpunkte"
                    onFilterSelected={(searchText) => { editorActions.filterSelectedMasspoints(searchText) }}
                    onFilterUnselected={(searchText) => { editorActions.filterUnselectedMasspoints(searchText) }}
                    selectionList={editor.masspointSelection}
                    selectEntities={(ids) => { editorActions.selectMasspoints(ids) }}
                    unselectEntities={(ids) => { editorActions.unselectMasspoints(ids) }}
                />
            </Grid>
        </Grid>
    );
}