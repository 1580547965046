import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import AdditionPositionService from 'services/addition_position.service';
import PositionCategoryService from 'services/position_category.service';
import PrepositionService from 'services/preposition.service';
import BaseDataService from "../../../../services/base_data.service";
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { buildSelectedDirectionRequests } from './position_category_create.reducer';

export const fetchAllData = createAsyncThunk(
    'positionCategory/create/fetchAllData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(fetchBodyAreas()),
            thunkApi.dispatch(fetchAdditionPositions()),
            thunkApi.dispatch(getPrepositions()),
        ]);
    }
)

export const fetchAdditionPositions = createAsyncThunk(
    'ositionCategory/create/fetchAdditionPositions',
    async (thunkApi) => {
        const response = await AdditionPositionService.getAdditionPositionsOverview();
        handleResponseErrors(response, "Zusatzpositionen");
        return response;
    }
)

export const getPrepositions = createAsyncThunk(
    'positionCategory/create/getPrepositions',
    async () => {
        const response = await PrepositionService.getPrepositions();
        handleResponseErrors(response, "Präposition");
        return response;
    }
)

export const fetchBodyAreas = createAsyncThunk(
    'positionCategory/create/fetchBodyAreas',
    async (thunkApi) => {
        const response = await BaseDataService.getBodyAreas();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const savePositionCategory = createAsyncThunk(
    'positionCategory/create/createPositionCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.position_category_create;
        const response = await PositionCategoryService.createPositionCategory({
            name: data.actualData.name,
            sideSeamAllowed: data.actualData.sideSeamAllowed,
            positionItems: data.actualData.positionCategoryItemSet.positionCategoryItems.map(x => {
                return {
                    isDocumentAllowed: x.isDocumentAllowed,
                    isDiameterAllowed: x.isDiameterAllowed,
                    diameterFrom: x.diameterFrom,
                    diameterTo: x.diameterTo,
                    isLengthAllowed: x.isLengthAllowed,
                    lengthFrom: x.lengthFrom,
                    lengthTo: x.lengthTo,
                    isWidthAllowed: x.isWidthAllowed,
                    widthFrom: x.widthFrom,
                    widthTo: x.widthTo,
                    displayAsPantyTop: x.isDisplayAsPantyTop,
                    sideSeamModeAllowed: data.actualData.sideSeamAllowed ? x.sideSeamModeAllowed : null,
                    selectedBodySides: x.bodySides.map(y => {
                        return {
                            bodySide: y
                        }
                    }),
                    selectedBodyAreas: x.bodyAreas.map(y => {
                        return {
                            bodyAreaId: y.id
                        }
                    }),
                    selectedDirectionRequests: buildSelectedDirectionRequests(x)
                }
            })
        });
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)