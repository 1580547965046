import { IPositionItemDirectionCreateItemModel } from "models/position_category/position_category_create";
import { ICategoryItemDetail, IPositionCategoryItem } from "models/position_category/position_category_editor"
import { ISelectable } from "shared/components/selectionComponent/models/selectable"
import { PositionCategoryEditState } from "./position_category_edit.model"
import { filterPositionCategoryValue } from "additions/position_categories/component/position_category_editor.reducer";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";

export const getPrepositions = (state: PositionCategoryEditState): ISelectable[] => {
    return state.loadedData.prepositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });
}

export const createEmptyPositionCategoryItem = (state: PositionCategoryEditState): IPositionCategoryItem => {
    const allHorizontalStartAdditionPositions: ISelectable[] = state.loadedData.horizontalStartAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const allHorizontalEndAdditionPositions: ISelectable[] = state.loadedData.horizontalEndAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const allVerticalStartAdditionPositions: ISelectable[] = state.loadedData.verticalStartAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    const allVerticalEndAdditionPositions: ISelectable[] = state.loadedData.verticalEndAdditionPositions.map(x => {
        return {
            id: x.id,
            displayName: x.name
        }
    });

    return {
        sequenceId: state.actualData.positionCategoryItemSet.positionCategoryItems.length,
        bodyAreas: [],
        bodySides: [],
        isDocumentAllowed: false,
        isLengthAllowed: false,
        isWidthAllowed: false,
        lengthFrom: 0,
        lengthTo: 0,
        widthFrom: 0,
        widthTo: 0,
        isDiameterAllowed: false,
        diameterFrom: 0,
        diameterTo: 0,
        isDisplayAsPantyTop: false,
        sideSeamModeAllowed: SideSeamModeEnum.Normal,
        horizontalStartItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allHorizontalStartAdditionPositions,
                    filteredItems: allHorizontalStartAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: getPrepositions(state),
                    filteredItems: getPrepositions(state),
                }
            },
            start: 0,
            end: 0,
        },
        verticalStartItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allVerticalStartAdditionPositions,
                    filteredItems: allVerticalStartAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: getPrepositions(state),
                    filteredItems: getPrepositions(state),
                }
            },
            start: 0,
            end: 0,
        },
        verticalEndItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allVerticalEndAdditionPositions,
                    filteredItems: allVerticalEndAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: getPrepositions(state),
                    filteredItems: getPrepositions(state),
                }
            },
            start: 0,
            end: 0,
        },
        horizontalEndItem: {
            additionPositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: allHorizontalEndAdditionPositions,
                    filteredItems: allHorizontalEndAdditionPositions,
                }
            },
            prepositionSelection: {
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: [],
                },
                unSelectedList: {
                    searchText: "",
                    allItems: getPrepositions(state),
                    filteredItems: getPrepositions(state),
                }
            },
            start: 0,
            end: 0,
        },
    }
}

export const calculateNewSequenceIds = (state: PositionCategoryEditState) => {
    let index = 0;
    state.actualData.positionCategoryItemSet.positionCategoryItems.forEach(x => {
        x.sequenceId = index;
        index = index + 1;
    });
}

export const updateCanSaveItem = (state: PositionCategoryEditState) => {
    state.command.canSavePositionCategoryItem.canExecute = state.currentEditedPositionCategoryItem.bodyAreas.length > 0 &&
        state.currentEditedPositionCategoryItem.bodySides.length > 0 &&
        rangeIsValid(state.currentEditedPositionCategoryItem.horizontalStartItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.horizontalStartItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.verticalStartItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.verticalStartItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.horizontalEndItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.horizontalEndItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.verticalEndItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.verticalEndItem) &&
        lengthInputIsValid(state.currentEditedPositionCategoryItem) &&
        widthInputIsValid(state.currentEditedPositionCategoryItem) &&
        diameterIsValid(state.currentEditedPositionCategoryItem);
}

const lengthInputIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isLengthAllowed) {
        if (item.lengthFrom === 0 || item.lengthTo === 0 || item.lengthFrom >= item.lengthTo) {
            return false;
        }
    }

    return true;
}

const widthInputIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isWidthAllowed) {
        if (item.widthFrom === 0 || item.widthTo === 0 || item.widthFrom >= item.widthTo) {
            return false;
        }
    }

    if (item.isWidthAllowed && !item.isLengthAllowed) {
        return false;
    }

    return true;
}

const diameterIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isDiameterAllowed) {
        if (item.diameterFrom === 0 || item.diameterTo === 0 || item.diameterFrom >= item.diameterTo) {
            return false;
        }
    }

    return true;
}

const selectedItemsAreValid = (itemDetail: ICategoryItemDetail): boolean => {
    return (itemDetail.prepositionSelection.selectedList.allItems.length === 0 || (itemDetail.prepositionSelection.selectedList.allItems.length > 0 && itemDetail.additionPositionSelection.selectedList.allItems.length > 0));
}

const rangeIsValid = (itemDetail: ICategoryItemDetail): boolean => {
    return itemDetail.start <= itemDetail.end;
}

export const updateCanSave = (state: PositionCategoryEditState) => {
    state.command.updatePositionCategory.canExecute = state.actualData.name.length > 0 &&
    state.actualData.positionCategoryItemSet.positionCategoryItems.length > 0 && 
    changedConfiguration(state);
}

const changedConfiguration = (state: PositionCategoryEditState) : boolean => {
    return state.actualData.name !== state.loadedData.positionCategory.name
    || state.actualData.sideSeamAllowed !== state.loadedData.positionCategory.sideSeamAllowed
    || itemConfigurationChanged(
        state.actualData.positionCategoryItemSet.positionCategoryItems,
        state.loadedData.positionCategory.positionCategoryItemSet.positionCategoryItems,
        state.actualData.sideSeamAllowed)
}

const itemConfigurationChanged = (actualItems: IPositionCategoryItem[], loadedItems: IPositionCategoryItem[], isSideSeamAllowed: boolean) : boolean => {
    let changedItems = false;
    
    actualItems.forEach(ai => {
        let itemEquals = false;
        loadedItems.forEach(li => {
            const loadedItemEquals = positionItemEquals(ai, li, isSideSeamAllowed);
            if(loadedItemEquals) {
                itemEquals = true;
                return;
            } 
        });

        if(itemEquals === false) {
            changedItems = true;
            return;
        }
    });
    return actualItems.length != loadedItems.length || changedItems;
}

const positionItemEquals = (actualItem: IPositionCategoryItem, loadedItem: IPositionCategoryItem, isSideSeamAllowed: boolean) : boolean => {
    return actualItem.isDocumentAllowed === loadedItem.isDocumentAllowed
    && actualItem.isDiameterAllowed === loadedItem.isDiameterAllowed
    && actualItem.diameterFrom === loadedItem.diameterFrom
    && actualItem.diameterTo === loadedItem.diameterTo
    && actualItem.isLengthAllowed === loadedItem.isLengthAllowed
    && actualItem.lengthFrom === loadedItem.lengthFrom
    && actualItem.lengthTo === loadedItem.lengthTo
    && actualItem.isWidthAllowed === loadedItem.isWidthAllowed
    && actualItem.widthFrom === loadedItem.widthFrom
    && actualItem.widthTo === loadedItem.widthTo
    && actualItem.isDiameterAllowed === loadedItem.isDiameterAllowed
    && actualItem.diameterFrom === loadedItem.diameterFrom
    && actualItem.diameterTo === loadedItem.diameterTo
    && actualItem.isDisplayAsPantyTop === loadedItem.isDisplayAsPantyTop
    && (actualItem.sideSeamModeAllowed === loadedItem.sideSeamModeAllowed || isSideSeamAllowed === false)
    && bodyAreasEquals(actualItem.bodyAreas, loadedItem.bodyAreas)
    && bodySidesEquals(actualItem.bodySides, loadedItem.bodySides)
    && directionEquals(actualItem.horizontalStartItem, loadedItem.horizontalStartItem)
    && directionEquals(actualItem.horizontalEndItem, loadedItem.horizontalEndItem)
    && directionEquals(actualItem.verticalStartItem, loadedItem.verticalStartItem)
    && directionEquals(actualItem.verticalEndItem, loadedItem.verticalEndItem);
}

const bodyAreasEquals = (actualBodyAreas: IAvailableBodyArea[], loadedBodyAreas: IAvailableBodyArea[]): boolean => {
    let  equalBodyAreas = true;
    actualBodyAreas.forEach(ab => {
        if(loadedBodyAreas.findIndex(lb => lb.id === ab.id) === -1){
            equalBodyAreas = false;
        }
    });
    return equalBodyAreas && actualBodyAreas.length === loadedBodyAreas.length;
}  

const bodySidesEquals = (actualBodySides: BodySideEnum[], loadedBodySides: BodySideEnum[]): boolean => {
    let  equalBodySides = true;
    actualBodySides.forEach(ab => {
        if(loadedBodySides.findIndex(lb => lb === ab) === -1){
            equalBodySides = false;
            return;
        }
    });
    return equalBodySides && actualBodySides.length === loadedBodySides.length;
}  

const directionEquals = (actualDirection: ICategoryItemDetail, loadedDirection: ICategoryItemDetail): boolean => {
    const isEqual = actualDirection.end === loadedDirection.end
        && actualDirection.start === loadedDirection.start

    let equalPositions = true;
    actualDirection.additionPositionSelection.selectedList.allItems.forEach(ab => {
        if(loadedDirection.additionPositionSelection.selectedList.allItems.findIndex(lb => lb.id === ab.id) === -1){
            equalPositions = false;
            return;
        }
    });
    equalPositions = equalPositions && actualDirection.additionPositionSelection.selectedList.allItems.length === loadedDirection.additionPositionSelection.selectedList.allItems.length;

    let equalPrepositions = true;
    actualDirection.prepositionSelection.selectedList.allItems.forEach(ab => {
        if(loadedDirection.prepositionSelection.selectedList.allItems.findIndex(lb => lb.id === ab.id) === -1){
            equalPositions = false;
            return;
        }
    });
    equalPrepositions = equalPrepositions && actualDirection.prepositionSelection.selectedList.allItems.length === loadedDirection.prepositionSelection.selectedList.allItems.length;

    return isEqual && equalPositions && equalPrepositions;
}  

export const buildSelectedDirectionRequests = (item: IPositionCategoryItem): IPositionItemDirectionCreateItemModel[] => {
    const result = new Array<IPositionItemDirectionCreateItemModel>();
    if (item.horizontalStartItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 1,
            start: item.horizontalStartItem.start,
            end: item.horizontalStartItem.end,
            selectedPositions: item.horizontalStartItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.horizontalStartItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    if (item.verticalStartItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 2,
            start: item.verticalStartItem.start,
            end: item.verticalStartItem.end,
            selectedPositions: item.verticalStartItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.verticalStartItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    if (item.horizontalEndItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 3,
            start: item.horizontalEndItem.start,
            end: item.horizontalEndItem.end,
            selectedPositions: item.horizontalEndItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.horizontalEndItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    if (item.verticalEndItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 4,
            start: item.verticalEndItem.start,
            end: item.verticalEndItem.end,
            selectedPositions: item.verticalEndItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.verticalEndItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
        });
    }

    return result;
}

export const filterPositionCategoryItems = (state: PositionCategoryEditState) => {
    let filteredItems = state.actualData.positionCategoryItemSet.positionCategoryItems;
    state.actualData.positionCategoryItemSet.filter.items.forEach(fil => {
        filteredItems = filterPositionCategoryValue(fil, filteredItems);
    });
    state.actualData.positionCategoryItemSet.filteredPositionCategoryItems = filteredItems;
}