import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import AdditionPositionService from 'services/addition_position.service';
import PositionCategoryService from 'services/position_category.service';
import BaseDataService from "../../../../services/base_data.service";
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { buildSelectedDirectionRequests } from './position_category_edit.reducer';
import AdditionPositionCategoryService from 'services/position_category.service';
import PrepositionService from 'services/preposition.service';

export const fetchAllData = createAsyncThunk(
    'positionCategory/edit/fetchAllData',
    async (positionCategoryId: string, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(fetchBodyAreas()),
            thunkApi.dispatch(getPrepositions()),
            thunkApi.dispatch(fetchAdditionPositions()),
        ]);
        await thunkApi.dispatch(getPositionCategory(positionCategoryId));
    }
)

export const fetchAdditionPositions = createAsyncThunk(
    'positionCategory/edit/fetchAdditionPositions',
    async (thunkApi) => {
        const response = await AdditionPositionService.getAdditionPositionsOverview();
        handleResponseErrors(response, "Zusatzpositionen");
        return response;
    }
)

export const getPrepositions = createAsyncThunk(
    'positionCategory/edit/getPrepositions',
    async () => {
        const response = await PrepositionService.getPrepositions();
        handleResponseErrors(response, "Präposition");
        return response;
    }
)

export const fetchBodyAreas = createAsyncThunk(
    'positionCategory/edit/fetchBodyAreas',
    async (thunkApi) => {
        const response = await BaseDataService.getBodyAreas();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getPositionCategory = createAsyncThunk(
    'positionCategory/edit/getPositionCategory',
    async (positionCategoryId: string, _) => {
        const response = await AdditionPositionCategoryService.getPositionCategory(positionCategoryId)
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)

export const updatePositionCategory = createAsyncThunk(
    'positionCategory/edit/updatePositionCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.position_category_edit;
        const response = await PositionCategoryService.updatePositionCategory({
            id: data.loadedData.positionCategoryId,
            name: data.actualData.name,
            sideSeamAllowed: data.actualData.sideSeamAllowed,
            positionItems: data.actualData.positionCategoryItemSet.positionCategoryItems.map(x => {
                return {
                    isDocumentAllowed: x.isDocumentAllowed,
                    isDiameterAllowed: x.isDiameterAllowed,
                    diameterFrom: x.diameterFrom,
                    diameterTo: x.diameterTo,
                    isLengthAllowed: x.isLengthAllowed,
                    lengthFrom: x.lengthFrom,
                    lengthTo: x.lengthTo,
                    isWidthAllowed: x.isWidthAllowed,
                    widthFrom: x.widthFrom,
                    widthTo: x.widthTo,
                    displayAsPantyTop: x.isDisplayAsPantyTop,
                    sideSeamModeAllowed: data.actualData.sideSeamAllowed ? x.sideSeamModeAllowed : null,
                    selectedBodySides: x.bodySides.map(y => {
                        return {
                            bodySide: y
                        }
                    }),
                    selectedBodyAreas: x.bodyAreas.map(y => {
                        return {
                            bodyAreaId: y.id
                        }
                    }),
                    selectedDirectionRequests: buildSelectedDirectionRequests(x)
                }
            })
        });
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)