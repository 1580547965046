import { IMasspointSelectable, MasspointFilterList } from "models/addition_position/addition_position_editor";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { AdditionPositionCreateState } from "./addition_position_create.model";

export const filterMasspoints = (listToFilter: MasspointFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => masspointMatchesText(m, listToFilter.searchText))
        .sort(sortMasspointCriteria);
}

export const masspointMatchesText = (m: ISelectable, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.displayName.toLowerCase().includes(searchTextLowerCase));
}

export const sortMasspointCriteria = (mp1: ISelectable, mp2: ISelectable) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const moveFromUnselectedToSelected = (state: AdditionPositionCreateState, ids: number[]) => {
    const selectedList = state.actualData.masspointSelection.selectedList;
    const unselectedList = state.actualData.masspointSelection.unSelectedList;

    ids.forEach(id => {
        const itemToMove = state.actualData.masspointSelection.unSelectedList.allItems.find(x => x.id === id);
        moveItemFromSourceToDestination(unselectedList, selectedList, itemToMove);
    });

    filterMasspoints(selectedList);
    filterMasspoints(unselectedList);
}

export const moveFromSelectedToUnselected = (state: AdditionPositionCreateState, ids: number[]) => {
    const selectedList = state.actualData.masspointSelection.selectedList;
    const unselectedList = state.actualData.masspointSelection.unSelectedList;

    ids.forEach(id => {
        const itemToMove = state.actualData.masspointSelection.selectedList.allItems.find(x => x.id === id);
        moveItemFromSourceToDestination(selectedList, unselectedList, itemToMove);
    });

    filterMasspoints(selectedList);
    filterMasspoints(unselectedList)
}

export const moveItemFromSourceToDestination = (source: MasspointFilterList, destination: MasspointFilterList, itemToMove: IMasspointSelectable) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}

export const updateCanSave = (state: AdditionPositionCreateState) => {
    return state.actualData.name.length > 0
        && state.actualData.name.length <= 20
        && (state.actualData.additionHierarchies.filter(x => x.isSelected).length > 0)
        && state.actualData.selectedAdditionPositionTranslation != null;

}